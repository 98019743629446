/* eslint-disable no-undefined */
import StyleDeduper from './StyleDeduper';
import Workbook from '../Workbook';
import { NameCSF, TableCSF } from '../csf';
import { CACHED_FORMULA_CELL_ID_PREFIX, EXTRACTED_SUBEXPRESSION_CELL_ID_PREFIX } from '../excel/constants';
import Cell from '../excel/Cell';
import { CSFOutput, CSFOutputCell, CSFOutputSheet } from './types';

function deepCopy (obj) {
  return JSON.parse(JSON.stringify(obj));
}

function isEmpty (obj): boolean {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }
  return true;
}

function isPrimitive (value: any): value is string | number | boolean | null {
  return typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number' || value == null;
}

const collectNameDefs = (cand: Record<string, Cell>, names: Map<string, NameCSF>, scope: string = '') => {
  const candidate = Object.values(cand);
  for (const cell of candidate) {
    // exclude dynamic formula defined-name objects as these should not be persisted.
    if (
      cell.id.startsWith(CACHED_FORMULA_CELL_ID_PREFIX) ||
      cell.id.startsWith(EXTRACTED_SUBEXPRESSION_CELL_ID_PREFIX)
    ) {
      continue;
    }
    if (cell.f) {
      const name: NameCSF = {
        name: cell.id,
        value: cell.f,
      };
      if (scope) {
        name.scope = scope;
      }
      const nameKey = name.scope ? `${name.scope}->${name.name}` : name.name;
      names.set(nameKey.toLowerCase(), name);
    }
  }
};

export function toCSF (workbook: Workbook): CSFOutput {
  const deduper = new StyleDeduper();
  const names: Map<string, NameCSF> = new Map();
  const tables: TableCSF[] = [];

  // collect globally scoped names
  collectNameDefs(workbook._globals, names);

  // collect tables
  if (workbook._tables.size) {
    for (const [ key, val ] of workbook._tables.entries()) {
      // Remove globals that point at tables, while tables are effectively globals
      // they are not stored or otherwise treated as such. No software supports
      // scoping tables (thankfully!) so we only worry about the global scope.
      names.delete(key.toLowerCase());
      // We hand these objects over to a consumer so we want to ensure that
      // they are copies.
      tables.push(deepCopy(val.csf));
    }
  }

  const sheets: CSFOutputSheet[] = workbook.getSheets().map(sheet => {
    const outSheet: CSFOutputSheet = {
      name: sheet.name,
      cells: {},
    };
    // has defaults?
    if (!isEmpty(sheet.defaults)) {
      outSheet.defaults = deepCopy(sheet.defaults);
    }
    // has row heights?
    if (!isEmpty(sheet.row_heights)) {
      outSheet.row_heights = deepCopy(sheet.row_heights);
    }
    // switch to col_widths if this sheet uses col_widths rather than columns
    if (!sheet.columns.length && !isEmpty(sheet.col_widths)) {
      outSheet.col_widths = deepCopy(sheet.col_widths);
    }
    else {
      outSheet.columns = deepCopy(sheet.columns);
    }
    if (sheet.show_grid_lines === false) {
      outSheet.show_grid_lines = false;
    }
    if (sheet.hidden === true) {
      outSheet.hidden = true;
    }

    collectNameDefs(sheet.locallyScopedNames, names, sheet.name);

    const spillRangeToAnchor = {};
    const spillAnchorExistsForRange = (fProp: string) => !!spillRangeToAnchor[fProp];

    // get spill ranges
    for (const cell of sheet.getCells()) {
      if (cell.F && spillRangeToAnchor[cell.F]) {
        // Already found anchor for spill range.
        continue;
      }
      if (!cell.F || !cell.f) {
        // Not an anchor cell.
        // A valid spill anchor cell should have a formula (see CLICKUP-4460).
        continue;
      }
      let id = cell.F.split(':')[0];
      if (/^\d+$/.test(id)) {
        // Row selection (1:1, 12:14)
        id = 'A' + id;
      }
      else if (/^[A-Z]+$/i.test(id)) {
        // Column selection (A:A, AB:AC)
        id = id + '1';
      }
      if (cell.id === id) {
        spillRangeToAnchor[cell.F] = cell;
      }
    }

    for (const cell of sheet.getCells()) {
      const outCell: CSFOutputCell = {};
      if (cell.f) {
        outCell.f = cell.f;
      }
      if (cell.F && spillAnchorExistsForRange(cell.F)) {
        outCell.F = cell.F;
      }
      if (cell.v != null || cell.f) {
        if (isPrimitive(cell.v)) {
          outCell.v = cell.v;
        }
        else if (cell.v instanceof Error) {
          outCell.v = String(cell.v);
          outCell.t = 'e';
        }
        // else we ignore the value; it is Matrix, Reference, or Lambda
      }
      const si = deduper.getSi(StyleDeduper.cellStyles(cell));
      if (si) {
        outCell.si = si;
      }
      // only save cells that have content
      if (Object.keys(outCell).length) {
        outSheet.cells[cell.id] = outCell;
      }
    }

    // transfer column styles to new styles
    if (workbook.styles && outSheet.columns) {
      for (const col of outSheet.columns) {
        if (typeof col.si === 'number') {
          col.si = deduper.getSi(workbook.styles[col.si] || {});
        }
      }
    }

    return outSheet;
  });

  const wb: CSFOutput = {
    schema_version: '4.10',
    id: workbook.id || '',
    filename: workbook.filename,
    type: workbook.type || 'native',
    sheets: sheets,
    styles: deduper.getStyles(),
  };
  if (names.size) {
    wb.names = Array.from(names.values());
  }
  if (tables.length) {
    wb.tables = tables;
  }
  if (workbook._iterativeCalculationSettings) {
    const calcProps = workbook._iterativeCalculationSettings;
    wb.calculation_properties = {
      iterate: !!calcProps.iterate,
      iterate_count: calcProps.maxIterations,
      iterate_delta: calcProps.maxChange,
    };
  }
  return wb;
}

import { default as RTree, intersects, contains, BBox } from './algorithm/rtree';
import Cell, { CellWithCellValue, ERROR_INVALID_CELLVALUE } from './excel/Cell';
import { ERROR_SPILL, MAX_COL, MAX_ROW, MODE_EXCEL, MODE_GOOGLE, MODE_GRID_SHEET } from './excel/constants';
import Matrix from './excel/Matrix';
import { isMatrix } from './excel/functions/utils';
import Reference, { isRef, type A1Reference } from './excel/Reference';
import Range, { SlimRange } from './excel/referenceParser/Range.js';
import { a1ToRowColumn, a1ToRowColumnOrNull } from './excel/referenceParser/a1.js';
import { invariant } from './validation';
import { translateRelative } from './moveCells';
import { MaybeBoxed, box, unbox } from './excel/ValueBox.js';
import { CellVertexId, RangeVertexId, vertexIdFromRange } from './DependencyGraph';
import { findSuccessfulClaimsBeforeGsdvClear, initGsdv } from './gsdv';
import { constructCellID } from './utils';
import { isCellValueBlank } from './utils-value';
import { ModeBit } from './excel/functions/sigtypes';
import { CellCSF, CellStyle } from './csf';
import { ResolveAreaOptions, type ReturnOptions } from './ResolveAreaOptions';
import { CellValue, type ArrayValue } from './excel/types';
import { type CallbackWithStop } from './Signal';
import { isCellValue } from './typeguards';
import type { AreaArrayAttributes, AreaArrayElement } from './AreaArray';
import { ERROR_CALC_LAMBDA_NOT_ALLOWED, isLambda } from './excel/lambda';

const MAX_SIZE_TO_SKIP_RTREE = 1000;

export type CellAttrs = {
  s: CellStyle,
  href: string,
};

type CellAttrsUpdate = {
  [K in keyof Partial<CellAttrs>]: CellAttrs[K] | null;
};

export type CellContainer = {
  getAttribute: <K extends keyof CellAttrs>(id: string, key: K) => CellAttrs[K] | undefined,
  setAttributes: (id: string, attrs: CellAttrsUpdate) => void,
  workbookKey: number,
  sheetIndex: number | null,
  mode: ModeBit,
};

export type RTreeCellNode = BBox & { cell: Cell };
export type RTreeMatrixNode = BBox & { matrix: Matrix, masked: boolean, valid: boolean, isRangeWrite?: boolean };
type AttrsRTreeNode = BBox & { attrs: Partial<CellAttrs> };

type ResolveAreaResult<O extends ResolveAreaOptions> = {
  area: AreaArrayElement<O>[][],
} & AreaArrayAttributes<AreaArrayElement<O>>;

/**
 * A node in a GRID sheet R-Tree representing a cell or range of cells.
 *
 * The `minX`, `minY`, `maxX` and `maxY` attributes are the zero-based column
 * and row indices of the corners of the range.
 *
 * Has _either_ but _not both_ of:
 *
 * - `cell` (for a node representing a single cell)
 *
 * - `matrix` (for a cell representing a rectangular area of more than one node,
 *   or of one node in the case of stored `#SPILL!` errors where we will not
 *   know the actual spill range until we recalculate it) and must not have both.
 *
 * For a cell node, the `masked` and `valid` attributes are not used. For a matrix node:
 *
 * - `masked` means this node represents a spill range that was prevented from
 *   spilling from its anchor cell.  We mask a cell when it contains a value
 *   which when spilled would intersect with an non-masked cell. The user sees
 *   masked cells as ones containing the `#SPILL!` error code.
 *
 * - `valid` means this node has been removed from its R-tree and should be disregarded (this is because
 *   Cell objects may still hold a reference to the spill range they _used_ to be in, and we'd like to avoid the
 *   work of finding them and removing that reference, when we remove an R-tree node).
 */
type RTreeNode = RTreeCellNode | RTreeMatrixNode;

type CellToEntry<O extends ReturnOptions> = (cell: Cell) => AreaArrayElement<O>;
type MatrixElementToEntry<O extends ReturnOptions> = (
  value: MaybeBoxed<CellValue>,
  matrixNode: RTreeMatrixNode,
  sheetY: number,
  sheetX: number
) => AreaArrayElement<O>;

/**
 * Converts a (row, col) tuple into an RTree compatible bounding box with area 1.
 */
function coordsToBBox (rowIndex: number, colIndex: number): BBox {
  return {
    minX: colIndex,
    maxX: colIndex,
    minY: rowIndex,
    maxY: rowIndex,
  };
}

export function boundingBoxToRange (bbox: BBox): Range {
  return new Range({ top: bbox.minY, left: bbox.minX, bottom: bbox.maxY, right: bbox.maxX });
}

export function boundingBoxToRangeCapped (bbox: BBox): Range {
  return new Range({
    top: bbox.minY,
    left: bbox.minX,
    bottom: Math.min(bbox.maxY, MAX_ROW),
    right: Math.min(bbox.maxX, MAX_COL),
  });
}

/**
 * Return whether node `a` blocks spilling from node `b` if they overlap.
 * Note that this does not check _whether_ they overlap, and there's no point calling this if they don't.
 * @returns true if an overlap between these nodes would cause `a` to block spilling from `b`.
 */
function isNodeBlocker (a: RTreeNode, b: RTreeNode): boolean {
  if (a.minX === b.minX && a.minY === b.minY) {
    // A cell cannot block itself. Node `a` will replace node `b` if inserted.
    return false;
  }
  if ('cell' in a) {
    if (a.cell.M != null && !('matrix' in b && b.isRangeWrite)) {
      return true;
    }
    if (a.cell.isBlank()) {
      return false;
    }
    const bIsCurrentSpillParent =
      a.cell._spill && a.cell._spill.valid && a.cell._spill.minX === b.minX && a.cell._spill.minY === b.minY;
    if (bIsCurrentSpillParent) {
      return false;
    }
  }
  if ('cell' in a || !a.masked) {
    return true;
  }
  // If a spilled range is masked, the the top-left cell containing the `#SPILL!`
  // error code can still block neighbouring cells from expanding. That is what
  // this check is for.
  return intersects(b, coordsToBBox(a.minY, a.minX));
}

/**
 * Turns a (row, col) pair into a key that can be used in hashmaps.
 */
export function createKey (rowIndex: number, colIndex: number): string {
  return `${rowIndex};${colIndex}`;
}

/**
 * Reverses operation of `createKey`.
 */
function deconstructKey (key: string): [row: number, col: number] {
  const parts = key.split(';');
  return [ +parts[0], +parts[1] ];
}

/**
 * Clears the value of the cell if its spill is stale.
 */
function clearValueIfSpillStale (cell: Cell): Cell {
  if (cell._v == null && cell.f == null && cell._spill != null && !cell._spill.valid) {
    // We have a cell which started out as blank, but is included in the CSF
    // because it has style information. If it has previously been spilled
    // into, but said spill is no longer valid, we must revert its value to
    // be blank again. See ClickUp CS-985.
    //
    // The `cell.f` check is because the `makeModel` function in our test
    // suite creates a spreadsheet model with broken invariants which
    // would never occur in vivo (sheet formula cell with `cell._v == null`).
    cell.v = null;
  }
  return cell;
}

type SearchDirection = 'right' | 'down' | 'left' | 'up';

const DIRECTION_OFFSETS: Record<SearchDirection, [number, number]> = {
  up: [ -1, 0 ],
  down: [ 1, 0 ],
  left: [ 0, -1 ],
  right: [ 0, 1 ],
};

export default class Cells {
  workbookKey: number;
  sheetIndex: number;
  mode: ModeBit;
  _cells: Map<string, Cell>;
  _attrs: Map<string, Partial<CellAttrs>>;
  _rtree: RTree<RTreeNode>;
  _attrsRtree: RTree<AttrsRTreeNode>;
  _cellContainer: CellContainer;
  _hasEverSpilled: boolean;
  /**
   * Map from top-left coords of spill anchor cells, represented by
   * 'createKey(row, col)', to an object containing:
   *
   *  - 'F', representing the whole range in A1 form (e.g. `"A1:B2"`), and
   *  - `matrix`, representing the reset state of the spill range.
   *
   * On initialization, we source 'F' from 'CellCSF' and collect the `matrix`
   * from the individual cells in the range specified by 'F'.
   *
   * After initialization, we keep '_spillResetState' up to date when edits
   * occur.
   *
   * When 'reset()' is invoked, we use '_spillResetState' to repopulate the
   * R-Tree with matrix nodes.
   */
  _spillResetState: Map<string, { matrix: Matrix, masked: boolean }>;
  /**
   * Set of unprefixed A1 addresses.
   *
   * These cells are a result of a spill in a Google Sheets workbook where the `F`
   * attribute is missing. This occurs when certain array functions (including
   * `FILTER`, `UNIQUE`, `QUERY`) are the spill source.
   *
   * This property is set to a non-null value only during the initial
   * recalculation. During said calculation, we don't let any cells in this
   * set block spills from expanding. At the end of the initial recalculation,
   * the cells corresponding to the addresses in this set are deleted, and the
   * property is set to null again.
   */
  _googleDummyValueCells: Set<string> | null;
  _gsdvClaimantOriginalValues: Map<string, CellValue>;

  /**
   * @param workbookKey The dependency graph key of the workbook these `Cells` are contained in.
   */
  constructor (workbookKey: number, sheetIndex: number) {
    invariant(Number.isInteger(workbookKey) && workbookKey >= 0);
    invariant(Number.isInteger(sheetIndex) && sheetIndex >= 0);

    this.workbookKey = workbookKey;
    this.sheetIndex = sheetIndex;
    this._cells = new Map();
    this._attrs = new Map();
    const self = this;
    this._cellContainer = {
      getAttribute: this.getCellAttribute.bind(this),
      setAttributes: this.setCellAttributes.bind(this),
      sheetIndex,
      workbookKey,
      get mode () {
        return self.mode;
      },
    };
    this.mode = MODE_GRID_SHEET;
    this._rtree = new RTree();
    this._attrsRtree = new RTree();
    this._hasEverSpilled = false;
    this._spillResetState = new Map();
    this._googleDummyValueCells = null;
    this._gsdvClaimantOriginalValues = new Map();
  }

  /**
   * Returns the number of single cells and spilled ranges stored in this class.
   */
  get cellCount (): number {
    return this._rtree.size;
  }

  /**
   * Populate this class with data from a CSF object.
   * @param cells CSF object representing the sheet
   * @param cellsWithGsdv addresses of cells with Google Sheets dummy values
   *   (spill results with unknown anchor cell)
   * @param [missingOK=false] pass true in order to tolerate spill ranges with
   *   some cells missing. The default is to abort populating such a spill range
   *   in order to leave it to the initial recalculation. Caller may want to
   *   depart from this default in the case where there will be no initial
   *   recalculation.
   * @returns a list of references to cells that need to be recalculated to
   *   determine spilled range sizes, and a list of references to of cells that
   *   have invalid values in the CSF.
   */
  setData (
    cells: Record<string, CellCSF>,
    cellsWithGsdv: string[],
    missingOK = false,
  ): [needInitRecalc: Reference[], haveInvalidValues: A1Reference[]] {
    // It's more efficient to batch-insert into R-Trees.
    const valuesRtreeData: RTreeNode[] = [];
    const attrsRtreeData: AttrsRTreeNode[] = [];
    const refsNeedingRecalc: Reference[] = [];
    const origCells = cells;

    /** GSDV handling, see {@link Docs.GSDV} */
    if (cellsWithGsdv.length !== 0) {
      const result = this.initGsdv(cells, cellsWithGsdv);
      cells = result.cells;
      Array.prototype.push.apply(refsNeedingRecalc, result.refsNeedingRecalc);
    }

    const haveInvalidValues: A1Reference[] = [];
    // All except spilled ranges
    for (const key of Object.keys(cells)) {
      const cellJson = cells[key];
      if (!cellJson) {
        console.warn(`Empty CSF cell at ${key} in sheet ${this.sheetIndex}; origCells[key]:`, origCells[key]);
      }
      if (isIgnorableCellCSF(cellJson)) {
        continue;
      }

      const [ rowIndex, colIndex ] = a1ToRowColumn(key);
      const cellKey = createKey(rowIndex, colIndex);

      const attrs = this._constructCellAttrs(cellJson);
      if (attrs) {
        this._attrs.set(cellKey, attrs);
        attrsRtreeData.push({ minX: colIndex, maxX: colIndex, minY: rowIndex, maxY: rowIndex, attrs: attrs });
      }

      if (!hasValueInformation(cellJson)) {
        continue;
      }
      const cell = new Cell(cellJson, key, this._cellContainer);
      if (cell.v === ERROR_INVALID_CELLVALUE) {
        haveInvalidValues.push(new Reference(cell.id) as A1Reference);
      }
      this._cells.set(cellKey, cell);

      if (cellJson.F) {
        // This cell is part of a spill range

        const spilledRef = Reference.from(cellJson.F);
        if (!spilledRef?.range) {
          continue;
        }
        const isOriginCell = rowIndex === spilledRef.top && colIndex === spilledRef.left;
        if (spilledRef.range.size > 1) {
          // If cell is not already marked as having an array formula, then mark
          // it thus, because it is the top-left cell of a spill range, so we
          // must just have been missing the array-type information because we
          // didn't read it from .xlsx into the CSF.
          cell.ft = 'a';
          if (!isOriginCell && cell.f) {
            // Cell contains a formula despite belonging to a spill range.
            // There exist Scratchpad workbooks with this flaw, see ENGINE-1006,
            // but we ought to be no longer creating more of them, and existing
            // ones ought to get cleaned up by this.
            cell.f = null;
          }
          if (isOriginCell) {
            this._spillResetState.set(createKey(spilledRef.range.top, spilledRef.range.left), {
              masked: false,
              // We will populate 'matrix' in the next for loop
              matrix: new Matrix(spilledRef.width, spilledRef.height),
            });
          }
          continue;
        }
        if (isOriginCell && cell.v === ERROR_SPILL) {
          // This cell is the top-left of a masked spilled range. We need to
          // recalculate to know the size of the spill.
          refsNeedingRecalc.push(spilledRef);
          cell._spill = Cells._createSpillErrorNode(cell as CellWithCellValue);
          valuesRtreeData.push(cell._spill);
          continue;
        }
      }

      // This is a single-cell R-tree node, not participating in a spill range.
      valuesRtreeData.push({ minX: colIndex, maxX: colIndex, minY: rowIndex, maxY: rowIndex, cell });
    }

    for (const [ key, spillRecord ] of this._spillResetState.entries()) {
      const [ top, left ] = deconstructKey(key);
      const { matrix } = spillRecord;
      const right = left + matrix.width - 1;
      const bottom = top + matrix.height - 1;
      const range = new Range({ top, left, right, bottom });
      // Require recalculation if any cells in the range are missing in the CSF.
      // This causes e.g. IMPORTRANGE to be attempted, which will fail, and the
      // resulting error will be picked up as reason to reinstate the GSDV cells
      // in `clearGsdv`. Yes, this is a bit roundabout. Might clean it up later.
      const { node, needsRecalc } = this._generateInitialRTreeMatrixNodeForSpillRange(range, matrix, missingOK);
      valuesRtreeData.push(node);

      if (needsRecalc) {
        const anchorCellRef = new Reference(
          boundingBoxToRange({
            minY: node.minY,
            maxY: node.minY,
            minX: node.minX,
            maxX: node.minX,
          }),
        );
        refsNeedingRecalc.push(anchorCellRef);
      }
    }
    this._rtree.load(valuesRtreeData);
    this._attrsRtree.load(attrsRtreeData);
    this._hasEverSpilled = this._spillResetState.size > 0;

    return [ refsNeedingRecalc, haveInvalidValues ];
  }

  /**
   * Update reset state for a given cell. This means mutating `Cell._v` and
   * `this._spilledRanges` to reflect the current states of cells, which may
   * differ from the initial states because formulas have been changed and
   * recalculated.
   *
   * NOTE: invoke this only immediately after a recalculation with no writes in
   * effect, else current cell values and spill states might not be the correct
   * ones for reset apply. Happily, when GRID scratchpad switches to edit mode,
   * it currently forces a reset of the document state to its original state,
   * thus satisfying this requirement.
   */
  updateResetState ({ row, column }: { row: number, column: number }) {
    const cell = this.getCellByCoords(row, column);
    if (cell != null) {
      cell._v = cell.valueBoxed;
    }
    const key = createKey(row, column);
    const previousSpillRecord = this._spillResetState.get(key);
    const currentSpillNode = cell && cell._spill && cell._spill.valid ? cell._spill : null;
    if (previousSpillRecord) {
      this._spillResetState.delete(key);
      const [ maxX, maxY ] = previousSpillRecord.masked
        ? [ column, row ]
        : [ column + previousSpillRecord.matrix.width - 1, row + previousSpillRecord.matrix.height - 1 ];
      const range = boundingBoxToRangeCapped({ minX: column, minY: row, maxX, maxY });
      this._clearSpillResetState(range, currentSpillNode);
    }
    if (currentSpillNode && cell?.isSpillAnchor()) {
      const currentF = cell.F;
      invariant(currentF != null, 'cell.F must be non-null because currentSpillNode is truthy, so cell._spill.valid');

      const { matrix, masked } = currentSpillNode;
      this._spillResetState.set(key, { matrix, masked });

      if (!currentSpillNode.masked) {
        this._updateResetStateOfSpilledCells(currentSpillNode);
      }
    }
  }

  /**
   * Clear the reset state for cells previously being spilled into, that are no
   * longer being spilled into. Meaning cell coordinates in `previousSpillRange`
   * that are not the top-left one and are not also in `currentSpill`. (The ones
   * also in `currentSpill` are handled in `_updateResetStateOfSpilledCells`.)
   */
  _clearSpillResetState (previousSpillRange: Range, currentSpill: BBox | null) {
    const row = previousSpillRange.top;
    const column = previousSpillRange.left;

    // Assume a 1x1 value if not present
    currentSpill ||= { minX: column, maxX: column, minY: row, maxY: row };

    // The spill range may have a different anchor from before, when a spill anchor is moved.
    const spillsHaveSameAnchorCell = currentSpill.minY === row && currentSpill.minX === column;
    const currentSpillEncompassesPrevSpill =
      spillsHaveSameAnchorCell &&
      previousSpillRange.right <= currentSpill.maxX &&
      previousSpillRange.bottom <= currentSpill.maxY;

    if (currentSpillEncompassesPrevSpill) {
      // Since the previous spill is entirely contained within the current spill, every cell
      // in the previous spill will receive a value from the current spill.
      return;
    }

    for (const range of previousSpillRange.except(boundingBoxToRangeCapped(currentSpill))) {
      for (const node of this._rtree.search(range.toBoundingBox())) {
        if ('cell' in node) {
          const { cell } = node;
          invariant(!cell.f, 'non-anchor cell in spill range should have no formula');
          cell.clear();
        }
      }
    }
  }

  /**
   * Set the reset state for cells currently being spilled into (which may or
   * may not have been previously spilled into).
   */
  _updateResetStateOfSpilledCells (spill: RTreeMatrixNode) {
    const nodes = this._rtree.search(spill);

    for (const node of nodes) {
      if (!('cell' in node)) {
        continue;
      }
      const { cell } = node;
      const x = node.minX - spill.minX;
      const y = node.minY - spill.minY;
      invariant(x < spill.matrix.width && y < spill.matrix.height);

      const boxedValue = spill.matrix.getBoxed(x, y);
      const cellValue = coerceLambdaToError(boxedValue);
      cell.v = cellValue;
      cell._v = cellValue;
      cell._spill = spill;
    }
  }

  reset () {
    this._resetAllCells();
    const removedNodes = this._removeSpillNodes();
    this._insertMatrixNodesForSpilledRanges();
    // Having removed all matrix nodes, we may end up with gaps in the R-tree for nodes that
    // started out as single cell nodes but were later replaced by matrix nodes. This will happen
    // if a cell has no .F attribute, but uses a formula function that can spill (e.g. INDEX, OFFSET).
    // These gaps must be filled.
    this._insertCellNodesIfMissing(removedNodes);
  }

  _resetAllCells () {
    for (const cell of this._cells.values()) {
      cell.v = cell.resetValueBoxed;
      cell._spill = null;
    }
  }

  getCellAttribute<K extends keyof CellAttrs> (cellId: string, key: K): CellAttrs[K] | undefined {
    if (!cellId) {
      // This can happen when reading attributes of a dummy cell which
      // has no ID (such as a blank dummy cell).
      return;
    }
    const addr = a1ToRowColumnOrNull(cellId);
    if (!addr) {
      // this can happen when reading attributes of a defined-name cell object
      return;
    }
    const [ row, column ] = addr;
    const attrs = this._attrs.get(createKey(row, column));
    return attrs?.[key];
  }

  setCellAttributes (cellId: string, attrsUpdate: CellAttrsUpdate): void {
    if (!cellId) {
      // This can happen when attempting to set the attributes of a
      // dummy cell which has no ID (such as a blank dummy cell).
      return;
    }
    const [ row, column ] = a1ToRowColumn(cellId);
    const cellKey = createKey(row, column);
    let attrs = this._attrs.get(cellKey);
    if (!attrs) {
      attrs = {};
      this._attrs.set(cellKey, attrs);
      this._attrsRtree.insert({ minX: column, maxX: column, minY: row, maxY: row, attrs });
    }
    let deleting = false;
    for (const key of [ 's', 'href' ] as (keyof CellAttrs)[]) {
      if (!(key in attrsUpdate)) {
        continue;
      }
      const value = attrsUpdate[key];
      if (value == null || (key === 's' && Object.keys(value).length === 0)) {
        deleting = true;
        delete attrs[key];
      }
      else if (key === 's') {
        attrs.s = Object.assign({}, value as Partial<Record<string, unknown>>);
      }
      else {
        attrs.href = value as string;
      }
    }
    if (deleting && Object.keys(attrs).length === 0) {
      this._attrs.delete(cellKey);
      const nodes = this._attrsRtree.search({ minX: column, maxX: column, minY: row, maxY: row });
      invariant(nodes.length === 1, 'a single attribute R-Tree node should be present, got ' + nodes.length);
      this._attrsRtree.remove(nodes[0]);
    }
  }

  /**
   * Remove from the R-tree any nodes which represent either current spill
   * ranges or cells which were spilling when this cell storage was populated.
   */
  _removeSpillNodes () {
    const nodes: RTreeNode[] = [];
    this._rtree.visitAll(node => {
      if ('matrix' in node || this._spillResetState.has(createKey(node.minY, node.minX))) {
        nodes.push(node);
      }
    });
    nodes.forEach(node => {
      if ('matrix' in node) {
        node.valid = false;
      }
      this._rtree.remove(node);
    });
    return nodes;
  }

  _insertMatrixNodesForSpilledRanges () {
    for (const [ key, { matrix, masked } ] of this._spillResetState.entries()) {
      const [ minY, minX ] = deconstructKey(key);
      const maxX = minX + matrix.width - 1;
      const maxY = minY + matrix.height - 1;
      const node = { minX, minY, maxX, maxY, matrix, masked, valid: true };
      const cell = this._cells.get(createKey(node.minY, node.minX));
      invariant(cell);
      cell._spill = node;
      this._rtree.insert(node);
      const cellNodesInSpillRange = this._findCellRTreeNodes(node, null);
      updateCellNodeSpillAssignments(cellNodesInSpillRange, null, node);
    }
  }

  /**
   * Attempts to collect values from every cell in the 'F' spill range
   * for a spill anchor cell on initialization. These values are used
   * to create the 'Matrix' of the initial R-Tree matrix node.
   *
   * For every coordinate in 'F', we collect the value of the cell at
   * that coordinate. If no cell exists at a coordinate, we cannot
   * fully populate the matrix. If this happens, we
   *
   *  - Stop collecting cell values to avoid unnecessary work. We are not
   *    able to collect the complete Matrix.
   *
   *  - Return `needsRecalc=true`, which communicates to the caller that
   *    we were not able to initialize the spill anchor node.
   *
   * If the caller receives `needsRecalc=true`, then they should add the
   * spill anchor cell to `refsNeedingRecalc`. By doing that, the cell
   * will be recalculated and the missing cells will be computed.
   *
   * Note:  We expect missing cells for any spill with '_defaultColumns',
   *        '_defaultRows', and '_defaultValue' that was saved. A large
   *        range selection such as A:A would create a million-ish cells,
   *        and it would be wasteful to save/keep those cells in memory.
   *
   *        Once we implement compact serialization for matrices in CellCSF
   *        we will be able to remove this behavior entirely.
   */
  _generateInitialRTreeMatrixNodeForSpillRange (
    range: Range,
    matrix: Matrix,
    missingCellsOK: boolean,
  ): { node: RTreeMatrixNode, needsRecalc: boolean } {
    const node = {
      minX: range.left,
      maxX: range.right,
      minY: range.top,
      maxY: range.bottom,
      masked: false,
      valid: true,
      matrix,
    };
    // Iterate from bottom-right so that we encounter missing cells earlier (missing cells are
    // most likely to occur in the default quadrants of spills).
    for (let y = range.height - 1; y >= 0; y -= 1) {
      const row = range.top + y;
      for (let x = range.width - 1; x >= 0; x -= 1) {
        const col = range.left + x;
        const cell = this._cells.get(createKey(row, col));
        // cell may be missing, e.g. in Google Sheets spills from IMPORTRANGE()
        if (cell) {
          cell._spill = node;
          // The cell's value can't be Matrix or Reference because cell is not a defined name
          matrix.set(x, y, cell.valueBoxed as MaybeBoxed<CellValue>);
        }
        else if (!missingCellsOK) {
          // Early-exit with `needsRecalc: true` to signal that this spill needs
          // to be recalculated, as it has cells missing.
          return { node, needsRecalc: true };
        }
      }
    }
    return { node, needsRecalc: false };
  }

  _insertCellNodesIfMissing (nodes: BBox[]) {
    for (const node of nodes) {
      const matches = this._rtree.search({ minX: node.minX, minY: node.minY, maxX: node.minX, maxY: node.minY });
      const topLeftMatch = matches.find(n => n.minX === node.minX && n.minY === node.minY);
      if (!topLeftMatch) {
        // Don't go through getCellByCoords because that will just get from
        // this._cells anyway, AND it will clear the v that we have just reset
        // from _v in _resetAllCells
        const cell = this._cells.get(createKey(node.minY, node.minX));
        if (cell) {
          this._rtree.insert({
            minX: node.minX,
            maxX: node.minX,
            minY: node.minY,
            maxY: node.minY,
            cell,
          });
        }
      }
    }
  }

  static _createSpillErrorNode (cell: CellWithCellValue): RTreeMatrixNode {
    const [ rowIndex, colIndex ] = a1ToRowColumn(cell.id);
    return {
      minX: colIndex,
      maxX: colIndex,
      minY: rowIndex,
      maxY: rowIndex,
      masked: true,
      valid: true,
      matrix: Matrix.of(cell.v),
    };
  }

  resolveArea<O extends ResolveAreaOptions> (
    range: SlimRange,
    options: O = new ResolveAreaOptions({ returnBoxed: false, returnCells: false }) as O,
  ): ResolveAreaResult<O> {
    const { top: minY, left: minX, bottom: maxY, right: maxX } = range;
    const { cellToEntry, matrixElementToEntry } = this._resolutionEntryMakers(options);

    const useHashmap = !this._hasEverSpilled && (maxY - minY + 1) * (maxX - minX + 1) < MAX_SIZE_TO_SKIP_RTREE;
    const result = useHashmap
      ? this._resolveAreaFromHashmap(maxX, maxY, minX, minY, cellToEntry, options)
      : this._resolveAreaFromRTree(maxX, maxY, minX, minY, cellToEntry, matrixElementToEntry, options);

    const { area, defaultRow } = result;
    if (defaultRow.length && area.length === 1 && area[0].length === 0) {
      // `area`'s first and only row is empty, but `defaultRow` is not.
      //
      // Maintain the invariant that `area[0].length === defaultRow.length` by
      // populating the first row using `defaultRow`.
      area[0] = [ ...defaultRow ] as (typeof area)[number];
    }
    // We don't need to handle `defaultColumn` as well because an area array
    // of `[ [] ]` satisfies the invariant `area.length === defaultColumn.length`

    return result as ResolveAreaResult<O>;
  }

  _resolutionEntryMakers<O extends ReturnOptions> (
    options: ReturnOptions,
  ): {
      cellToEntry: CellToEntry<O>,
      matrixElementToEntry: MatrixElementToEntry<O>,
    } {
    let cellToEntry: CellToEntry<O>;
    let matrixElementToEntry: MatrixElementToEntry<O>;

    if (options.returnCells) {
      cellToEntry = cell => cell as AreaArrayElement<O>;
      matrixElementToEntry = (element, matrixNode, sheetY, sheetX) => {
        const cell =
          this._cells.get(createKey(sheetY, sheetX)) ||
          new Cell({}, constructCellID(sheetY, sheetX), this._cellContainer);
        // Non-obvious ordering requirement: we assign _spill before v because
        // CellMutationMonitor has to ignore .v setter mutations if isSpilled()
        cell._spill = matrixNode;
        cell.v = element;
        return cell as AreaArrayElement<O>;
      };
    }
    else if (options.returnBoxed) {
      cellToEntry = cell => {
        // Only defined-name cells can have Matrix/Reference values
        const cellValue = cell.v as CellValue;
        return (cell.z ? box(cellValue, { numberFormat: cell.z }) : cellValue) as AreaArrayElement<O>;
      };
      matrixElementToEntry = (element, _, y, x) => {
        const cell = this._cells.get(createKey(y, x));
        if (cell && cell.userZ) {
          return box(unbox(element), { numberFormat: cell.userZ }) as AreaArrayElement<O>;
        }
        return element as AreaArrayElement<O>;
      };
    }
    else {
      // Only defined-name cells can have Matrix/Reference values
      cellToEntry = cell => cell.v as AreaArrayElement<O>;
      matrixElementToEntry = element => unbox(element) as AreaArrayElement<O>;
    }
    return { cellToEntry, matrixElementToEntry };
  }

  /**
   * Quicker implementation of resolveArea for the case where the sheet has seen
   * no spilling and the range being requested is smaller than a threshold.
   */
  _resolveAreaFromHashmap<O extends ResolveAreaOptions> (
    maxX: number,
    maxY: number,
    minX: number,
    minY: number,
    cellToEntry: CellToEntry<O>,
    options: O,
  ): ResolveAreaResult<O> {
    type Value = AreaArrayElement<O>;

    // Use a quicker lookup method if this sheet has never seen any spillage.
    // Skip the R-tree (except that getSize() uses its root node for the bounds)
    const [ sheetWidth, sheetHeight ] = this.getSize();
    const maxIterY = Math.min(maxY, sheetHeight - 1);
    const maxIterX = Math.min(maxX, sheetWidth - 1);
    const iterHeight = maxIterY - minY + 1;
    const iterWidth = maxIterX - minX + 1;
    let area: Value[][];
    let maxDataY = minY - 1;
    let maxDataX = minX - 1;
    if (iterHeight <= 0 || iterWidth <= 0) {
      area = [ [] ];
    }
    else {
      area = [];
      let blankRow: Value[];
      try {
        blankRow = Array(iterWidth).fill(null);
      }
      catch {
        throw new Error(`Bad iterWidth, from maxIterX ${maxIterX} minX ${minX} maxX ${maxX} sheetWidth ${sheetWidth}`);
      }
      const singleRow = maxIterY === minY;
      for (let y = minY; y <= maxIterY; y++) {
        const row: Value[] = singleRow ? blankRow : blankRow.slice();
        for (let x = minX; x <= maxIterX; x++) {
          const cellKey = createKey(y, x);
          let cell = this._cells.get(cellKey);
          const hasNonBlankValue = cell && !isCellValueBlank(cell.v);
          if (!cell && this._attrs.has(cellKey)) {
            cell = this._makeCell(y, x, { insert: false });
          }
          if (cell) {
            row[x - minX] = cellToEntry(cell);
            const expandBoundsToIncludeCell =
              options.cropTo === 'cells-with-non-blank-values' ? hasNonBlankValue : true;
            if (expandBoundsToIncludeCell) {
              if (maxDataY < y) {
                maxDataY = y;
              }
              if (maxDataX < x) {
                maxDataX = x;
              }
            }
          }
        }
        area.push(row);
      }
      // shorten arrays if a bottom and/or rightmost stripe is all blank
      if (maxDataY < maxIterY) {
        area.length = maxDataY - minY + 1;
      }
      if (maxDataX < maxIterX) {
        const dataWidth = maxDataX - minX + 1;
        for (const row of area) {
          row.length = dataWidth;
        }
      }
    }
    return {
      area,
      top: minY,
      left: minX,
      bottom: maxY,
      right: maxX,
      dataBottom: maxDataY,
      dataRight: maxDataX,
      defaultRow: [],
      defaultColumn: [],
      defaultValue: null,
    };
  }

  /**
   * Implementation of resolveArea using R-tree, efficient on big sparse areas.
   */
  _resolveAreaFromRTree<O extends ResolveAreaOptions> (
    maxX: number,
    maxY: number,
    minX: number,
    minY: number,
    cellToEntry: CellToEntry<O>,
    matrixElementToEntry: MatrixElementToEntry<O>,
    options: O,
  ): ResolveAreaResult<O> {
    // Collect and organize source data
    const collectResult = collectAreaNodes(this._rtree, minX, maxX, minY, maxY, options);
    const { cellNodes, unmaskedMatrixNodes: matrixNodes, maskedMatrixNodes } = collectResult;
    let { maxDataX, maxDataY } = collectResult;
    let attrNodes: AttrsRTreeNode[] = [];
    if (options.returnCells) {
      const [ sheetWidth, sheetHeight ] = this.getSize();
      maxY = Math.min(maxY, sheetHeight - 1);
      maxX = Math.min(maxX, sheetWidth - 1);
      minX = Math.min(minX, maxX);
      minY = Math.min(minY, maxY);
      maxDataX = Math.min(maxDataX, maxX);
      maxDataY = Math.min(maxDataY, maxY);

      attrNodes = this._attrsRtree.search({ minX, maxX, minY, maxY });

      if (options.cropTo === 'any-cell-information') {
        // Expand bounds to encompass cells that only have non-value information
        for (const attrNode of attrNodes) {
          if (attrNode.minX > maxDataX) {
            maxDataX = attrNode.minX;
          }
          if (attrNode.minY > maxDataY) {
            maxDataY = attrNode.minY;
          }
        }
      }
    }
    const numRows = maxDataY - minY + 1;
    const numCols = maxDataX - minX + 1;

    // Initialize dense area array populated with nulls (or dummy blank cells with IDs, if returning cells)
    const area: AreaArrayElement<O>[][] = Array(numRows);
    if (numRows > 0) {
      const blankRow: AreaArrayElement<O>[] = Array(numCols).fill(null);
      area[0] = blankRow;
      for (let r = 1; r < numRows; r++) {
        area[r] = blankRow.slice();
      }
    }

    // Populate area array from cell nodes
    for (const cellNode of cellNodes) {
      const entry = cellToEntry(cellNode.cell);
      const relY = cellNode.minY - minY;
      const relX = cellNode.minX - minX;
      if (relY >= area.length || relX >= area[relY].length) {
        // Out-of-bounds. This can happen when `cropTo` is `cells-with-non-blank-values`
        continue;
      }
      area[relY][cellNode.minX - minX] = entry;
    }

    // Populate area array and default column & row, from unmasked matrix nodes
    const defaultColumn: AreaArrayElement<O>[] = Array(numRows).fill(null);
    const defaultRow: AreaArrayElement<O>[] = Array(numCols).fill(null);
    let defaultValue: AreaArrayElement<O> = null;
    for (const matrixNode of matrixNodes) {
      const mx = matrixNode.matrix;
      const startX = Math.max(minX, matrixNode.minX);
      const startY = Math.max(minY, matrixNode.minY);
      const endX = Math.min(maxDataX, matrixNode.maxX);
      const endY = Math.min(maxDataY, matrixNode.maxY);
      for (let y = startY; y <= endY; y += 1) {
        const arrayY = y - minY;
        const matrixY = y - matrixNode.minY;
        const areaRow = area[arrayY];
        for (let x = startX; x <= endX; x += 1) {
          const arrayX = x - minX;
          const matrixX = x - matrixNode.minX;
          const boxedValue = mx.getBoxed(matrixX, matrixY);
          const element = coerceLambdaToError(boxedValue);
          areaRow[arrayX] = matrixElementToEntry(element, matrixNode, y, x);
        }
        // Top-right quadrant
        if (!options.returnCells && maxX > maxDataX && matrixNode.maxX >= maxX && mx.populatedWidth < mx.width) {
          defaultColumn[arrayY] = matrixElementToEntry(
            coerceLambdaToError(mx.getBoxed(maxDataX - matrixNode.minX + 1, matrixY)),
            matrixNode,
            y,
            maxDataX + 1,
          );
        }
      }
      if (!options.returnCells) {
        // Bottom-left quadrant
        if (maxY > maxDataY && matrixNode.maxY >= maxY && mx.populatedHeight < mx.height) {
          for (let x = startX; x <= endX; x += 1) {
            const arrayX = x - minX;
            const matrixX = x - matrixNode.minX;
            defaultRow[arrayX] = matrixElementToEntry(
              coerceLambdaToError(mx.getBoxed(matrixX, maxDataY - matrixNode.minY + 1)),
              matrixNode,
              maxDataY + 1,
              x,
            );
          }
          // Bottom-right quadrant
          if (maxX > maxDataX && matrixNode.maxX >= maxX && mx.populatedWidth < mx.width) {
            defaultValue = matrixElementToEntry(
              coerceLambdaToError(mx._defaultValue),
              matrixNode,
              maxDataY + 1,
              maxDataX + 1,
            );
          }
        }
      }
    }

    // Populate area array with #SPILL! errors from masked matrix nodes
    for (const maskedMatrixNode of maskedMatrixNodes) {
      const areaY = maskedMatrixNode.minY - minY;
      const areaX = maskedMatrixNode.minX - minX;
      area[areaY][areaX] = matrixElementToEntry(
        ERROR_SPILL,
        maskedMatrixNode,
        maskedMatrixNode.minY,
        maskedMatrixNode.minX,
      );
    }

    // Empty `defaultRow` and `defaultColumn` if they contain nothing different from `defaultValue`
    if (
      defaultRow.every(
        element =>
          typeof element === 'undefined' ||
          element === defaultValue ||
          (element instanceof Cell && element.v === ((defaultValue as Cell)?.v ?? null)),
      )
    ) {
      defaultRow.splice(0);
    }
    if (
      defaultColumn.every(
        element =>
          typeof element === 'undefined' ||
          element === defaultValue ||
          (element instanceof Cell && element.v === ((defaultValue as Cell)?.v ?? null)),
      )
    ) {
      defaultColumn.splice(0);
    }

    // For now, maintain the convention that an empty AreaArray contains an
    // empty first row instead of no rows. Do that because existing code in
    // both Apiary and GRID-client is subtly and unknowingly coupled to this
    // assumption, and cleaning that up is best done gingerly, separately, and
    // probably in concert with getting rid of AreaArrays altogether.
    if (area.length === 0) {
      area.push([]);
    }

    if (options.returnCells) {
      // At this point, the `area` array has been populated with `Cell` objects
      // for every coordinate that has a value. However, coordinates that only
      // have non-value information are still populated with `null`.
      //
      // Amend by returning empty cell objects for coordinates that have non-value
      // information associated with them.
      for (const node of attrNodes) {
        const relX = node.minX - minX;
        const relY = node.minY - minY;
        if (relY >= area.length || relX >= area[relY].length) {
          // Out-of-bounds. This can happen when `cropTo` is `cells-with-non-blank-values`
          continue;
        }
        if (!area[relY][relX]) {
          area[relY][relX] = this._makeCell(node.minY, node.minX, { insert: false }) as AreaArrayElement<O>;
        }
      }
    }

    return {
      area,
      top: minY,
      left: minX,
      bottom: maxY,
      right: maxX,
      dataBottom: maxDataY,
      dataRight: maxDataX,
      defaultRow,
      defaultColumn,
      defaultValue,
    };
  }

  /**
   * @param cellAddr cell address in A1 form without prefix
   */
  getCellByID (cellAddr: string): Cell | null {
    const [ rowIndex, colIndex ] = a1ToRowColumn(cellAddr);
    return this.getCellByCoords(rowIndex, colIndex);
  }

  /**
   * @param preferCell If set to true, an individual cell R-Tree
   * node will be preferred instead of the matrix (spilling) R-Tree node. This
   * matters when you want a cell R-Tree node that may be covered by a spill.
   */
  _getNodeByCoords (row: number, col: number, preferCell = false): RTreeNode | null {
    let results = this._rtree
      .search(coordsToBBox(row, col))
      .filter(n => 'cell' in n || !n.masked || (n.minX === col && n.minY === row));
    if (results.length === 0) {
      return null;
    }
    if (results.length !== 1) {
      // This if statement may be kind of confusing if you weren't involved in
      // writing this code. At any given (row, col) coordinates, the following invariants hold:
      //   1. No R-Tree nodes intersects (row, col). Handled above by early return.
      //   2. One R-Tree node intersects (row, col). Handled by not taking this branch.
      //   3. Two R-Tree nodes intersect (row, col). One of which is a `cell`
      //     node whose `Cell` instance has a value of `null`, and the other is
      //     a `matrix` node. Handled below.
      results = results.filter(n => {
        if (preferCell) {
          return 'cell' in n;
        }
        return 'matrix' in n;
      });
      if (results.length !== 1) {
        // This violates one of our run-time invariants. There should never
        // be overlapping ranges where the number of matrix nodes or cell
        // nodes is not 1 (see above comment).
        throw new Error(
          `Internal spilling error: cell (${row}, ${col}) is contained in ${results.length} unmasked matrix ranges`,
        );
      }
    }
    return results[0];
  }

  /**
   * @param willEdit pass true if the cell will be edited. In
   *   that case a cell that is present only in the hashmap and not the R-tree
   *   will be inserted also into the R-tree before returning it; this is
   *   otherwise avoided in the interest of keeping the R-tree free of cells
   *   that have no value or formula, only style information.
   *   FIXME: clean up, or abstract better, this “ghost” state of a cell.
   * @param notSpilled true if the desired cell is known not to be a spilled
   *   cell; this allows a quicker lookup, sidestepping the R-tree.
   */
  getCellByCoords (row: number, col: number, willEdit = false, notSpilled = false): Cell | null {
    if (!Number.isInteger(row) || !Number.isInteger(col) || row < 0 || col < 0) {
      throw new Error(`Invalid cell coordinates: ${row}, ${col}`);
    }

    // Use a quicker lookup method, since spilled cells do not need to be found
    const cellKey = createKey(row, col);
    const fastCell = this._cells.get(cellKey);
    if (notSpilled || !this._hasEverSpilled) {
      if (!fastCell && this._attrs.has(cellKey)) {
        return this._makeCell(row, col, { insert: willEdit });
      }
      return fastCell || null;
    }
    else if (fastCell && fastCell.f) {
      return fastCell;
    }

    const result = this._getNodeByCoords(row, col, willEdit);
    if (result == null) {
      // There's no value at this coordinate, but there may non-value information.
      if (this._attrs.has(createKey(row, col))) {
        return this._makeCell(row, col, { insert: willEdit });
      }
      return null;
    }

    if ('cell' in result) {
      return clearValueIfSpillStale(result.cell);
    }
    // Now we know `result` is a matrix node
    if (!result.valid) {
      // spill range is not valid, either because it was removed from the R-tree
      // or because we are evaluating the formula of the anchor cell of this
      // selfsame spill range in `Workbook.calcCell`; see comment there.
      return null;
    }
    let cell = this._cells.get(createKey(row, col));
    const isAnchorCell = result.minX === col && result.minY === row;
    if (result.masked && cell && isAnchorCell) {
      cell.v = ERROR_SPILL;
      cell._spill = result;
      return cell;
    }
    if (!cell) {
      // Spilled into a cell which wasn't present in the CSF.
      cell = this._makeCell(row, col, { insert: false });
      if (willEdit) {
        this._cells.set(createKey(row, col), cell);
      }
    }
    if (!result.masked && !isAnchorCell && willEdit) {
      // We are about to edit into a non-anchor node of an unmasked
      // spill, and there does not exist an R-Tree node for the cell
      // we are about to edit into.
      //
      // Insert an R-Tree node for the cell and return the cell.
      this._rtree.insert({ minX: col, maxX: col, minY: row, maxY: row, cell });
      return cell;
    }
    this._assignSpillToCell(cell, result, row, col);
    return cell;
  }

  /**
   * Get the area which a spilled range covers, given the position of the
   * spilled range's anchor cell. Returns null if `(row, col)` is not a
   * formula cell.
   */
  getSpillAnchoredAtCoords (row: number, col: number): Range | null {
    const node = this._getNodeByCoords(row, col);
    if (!node || node.minY !== row || node.minX !== col) {
      return null;
    }
    if ('cell' in node && node.cell.f == null) {
      return null;
    }
    return boundingBoxToRange(node);
  }

  _searchMasked (bbox: BBox): RTreeMatrixNode[] {
    return this._rtree.search(bbox).filter(n => 'matrix' in n && n.masked) as RTreeMatrixNode[];
  }

  _searchBlockers (node: RTreeNode): RTreeNode[] {
    return this._rtree.search(node).filter(n => isNodeBlocker(n, node));
  }

  /**
   * {@link Docs.GSDV}
   */
  initGsdv (
    originalCells: Record<string, CellCSF>,
    cellsWithGsdv: string[],
  ): { cells: Record<string, CellCSF>, refsNeedingRecalc: Reference[] } {
    const { cells, refsNeedingRecalc, claimantValues, gsdvSet } = initGsdv(originalCells, cellsWithGsdv);
    this._gsdvClaimantOriginalValues = claimantValues;
    this._googleDummyValueCells = gsdvSet;
    return { cells, refsNeedingRecalc };
  }

  /**
   * Clear any GSDV-related state. Before clearing, this method attempts to
   * resolve unclaimed GSDV cells.
   */
  clearGsdv () {
    if (!this._googleDummyValueCells) {
      return;
    }

    const claims = findSuccessfulClaimsBeforeGsdvClear(
      [ ...this._googleDummyValueCells ],
      this.getCellByCoords.bind(this),
    );
    for (const { claimant, range } of claims) {
      const spillAnchor = this.getCellByID(claimant);
      invariant(spillAnchor);

      // Spill anchor formula must be an array formula, since it claims the GSDV
      spillAnchor.ft = 'a';

      // Restore the value that the spill anchor had before the initial recalc.
      //
      // This must happen before we populate the spill Matrix.
      spillAnchor.v = this._gsdvClaimantOriginalValues.get(claimant)!;

      // Create R-Tree matrix node for the spill range.
      const matrix = new Matrix(range.width, range.height);
      const { node } = this._generateInitialRTreeMatrixNodeForSpillRange(range, matrix, true);
      this._rtree.insert(node);

      for (const { row, column } of range.iterCoordinates()) {
        // Marks cells as belonging to the spill
        const cell = this._cells.get(createKey(row, column));
        if (cell) {
          cell._spill = node;
          // No longer consider cells in spill range as GSDV cells.
          const cellId = constructCellID(row, column);
          this._googleDummyValueCells.delete(cellId);
        }
      }
    }

    for (const cellAddr of this._googleDummyValueCells) {
      const [ row, col ] = a1ToRowColumn(cellAddr);
      const nodes = this._rtree.search(coordsToBBox(row, col));
      // There may be 0 nodes in the case of GSDV cells containing only non-value
      // information (such as styles).
      invariant(nodes.length <= 1, 'expected <=1 R-tree nodes for unclaimed GSDV cell, got ' + nodes.length);
      if (nodes.length > 0) {
        this._rtree.remove(nodes[0]);
        this._cells.delete(createKey(row, col));
      }
    }
    this._googleDummyValueCells = null;
    this._gsdvClaimantOriginalValues.clear();
  }

  /**
   * Replace the current (_possibly_ multi-cell) value anchored at (having
   * top-left cell address at) the top-left corner of `range`, with the given
   * (also _possibly_ multi-cell) `value`, which has different dimensions.
   *
   * This entails:
   * * deleting the existing R-tree node anchored at that address,
   * * adding a new one containing `value`,
   * * marking the new R-tree node masked if it is prevented from spilling by
   *   any other R-tree nodes overlapping the spill area),
   * * recursively marking any other R-tree nodes unmasked that were previously
   *   blocked from spilling but become unblocked by the changes made here
   *
   * @param range coordinates of a formula cell
   *   or of the top-left cell of a “range write” (multi-cell write operation).
   * @param value a new value for that cell.
   *   This should have different dimensions from the value (cell or matrix) of
   *   the existing R-tree node (else there is no need to replace it and calling
   *   this function is wasteful).
   * @param isRangeWrite true if the value is being applied by
   *   a range write, not a spill from a formula cell. This has two effects:
   *   1. this will override any existing spill node, so existing spill nodes
   *      will be blocked by this, rather than this by them
   *   2. the R-tree node will be marked accordingly, letting subsequent writes
   *      merge with this one rather than block it or be blocked by it.
   * @returns a list of ranges whose cell values may
   *   have changed because spill ranges got unblocked by this change.
   *   This includes the range supplied to this in the first argument, or the
   *   previous spill range if this new spill range gets blocked
   */
  updateValueAndSpills (
    range: { top: number, left: number },
    value: MaybeBoxed<CellValue> | Matrix,
    isRangeWrite = false,
  ): Range[] {
    if (isRef(range) && !range.isAddress) {
      throw new Error("name references can't be assigned spilled ranges");
    }
    const { top, left } = range;

    let width = 1;
    let height = 1;
    if (isMatrix(value)) {
      width = value.width;
      height = value.height;
    }
    // This is the bounding box of the new value.
    const bbox = {
      minX: left,
      maxX: left + width - 1,
      minY: top,
      maxY: top + height - 1,
    };
    // We search for all nodes that intersect the above bounding box.
    const intersectingNodes = this._rtree.search(bbox);
    // The above search should always yield at least one result, which is the node
    // containing the old value that we're replacing. In the logic below, we
    // e.g. need to know if the old node was masked, and at the end we need to
    // mark it invalid.
    const oldNode = intersectingNodes.find(node => node.minX === left && node.minY === top);
    invariant(oldNode != null);
    // Complete the new node
    let newNode: RTreeNode;
    if (isMatrix(value)) {
      newNode = Object.assign({ matrix: value, masked: false, valid: true, isRangeWrite }, bbox);
      this._hasEverSpilled = true;
    }
    else {
      const cell = this._cells.get(createKey(top, left));
      invariant(cell != null);
      cell.v = value;
      newNode = Object.assign({ cell }, bbox);
    }

    const cellNodesInPrevOrNewSpillRange = this._findCellRTreeNodes(oldNode, newNode);

    // These are the nodes containing non-masked cells whose values intersect
    // with the bounding box of our new cell value.
    let blockingNodes = intersectingNodes.filter(n => isNodeBlocker(n, newNode));

    // Don't let cells whose address is contained in `this._googleDummyValueCells`
    // block spills.
    if (blockingNodes.length > 0 && this._googleDummyValueCells) {
      const gsdv = this._googleDummyValueCells;
      blockingNodes = blockingNodes.filter(node => {
        if ('cell' in node && gsdv.has(node.cell.id)) {
          // We delete the cells here even though we're also going to do so in
          // `this.clearGsdv` at the end of this initial recalculation. The
          // reason is to maintain the invariant that if two R-Tree nodes
          // intersect the same `(row, col)` location, one of them is a Matrix
          // node and the other is a cell with a blank value. We maintain that
          // invariant by deleting the node here, instead of setting the node's
          // cell value to blank. That's because removing a node here is more
          // efficient because we don't have to search for the node again based
          // on the cell address.
          this._rtree.remove(node);
          this._cells.delete(createKey(node.minY, node.minX));
          // If we didn't delete the cell ID from the `gsdv` set here, we would
          // end up deleting the matrix node that will occupy its location (the
          // one we will create below).
          gsdv.delete(node.cell.id);
          return false;
        }
        return true;
      });
    }

    this._rtree.remove(oldNode);
    if ('matrix' in oldNode) {
      oldNode.valid = false;
    }

    const [ spillPopulatedRight, spillPopulatedBottom ] =
      'matrix' in newNode ? this.getSpillPopulatedRightBottom(newNode) : [ newNode.minX, newNode.minY ];

    let changedRanges: Range[];
    if (isRangeWrite) {
      // Range writes always take precedence over existing matrix nodes:
      //
      //  - If the range write overlaps the anchor of a matrix node, that
      //    matrix node is entirely removed.
      //
      //  - If the range write overlaps non-anchor cells of a matrix node, that
      //    matrix node becomes blocked.
      const previouslyBlockedNodes: RTreeMatrixNode[] = [];
      for (const overriddenNode of blockingNodes) {
        // Before we invoke 'updateValueAndSpills' for range writes, we should
        // have found every cell node in the write range and neutralized them
        // (i.e. made them non-blocking).
        invariant('matrix' in overriddenNode, 'cell nodes in write range have been neutralized');

        // Remove it if we are overwriting its anchor, else block it
        previouslyBlockedNodes.push(...this._searchMasked(overriddenNode));
        const overriddenAnchor = { ...overriddenNode, maxX: overriddenNode.minX, maxY: overriddenNode.minY };
        if (intersects(newNode, overriddenAnchor)) {
          this._rtree.remove(overriddenNode);
          overriddenNode.valid = false;
        }
        else {
          overriddenNode.masked = true;
        }
      }

      // Insert before unblocking other nodes
      this._rtree.insert(newNode);

      // Try to unblock nodes that were blocked by spills we just removed/blocked
      changedRanges = [ boundingBoxToRangeCapped(newNode) ].concat(
        this._unmaskCurrentlyNotBlockedNodes(previouslyBlockedNodes),
      );
    }
    else if (blockingNodes.length >= 1 || spillPopulatedBottom > MAX_ROW || spillPopulatedRight > MAX_COL) {
      invariant('matrix' in newNode);

      // If the new spill range is blocked by any non-masked cells, we need to
      // mask the cell, and consequently possibly unmask some other cells that
      // were previously blocked by it.
      //
      // First find any currently masked R-tree nodes overlapping this node's
      // _previous_ spill range (do this before inserting the new node, as it
      // could only make extra work for this search)

      // Order matters. We must insert the new node into the tree before
      // checking for nodes we can potentially unblock.
      const previouslyBlockedNodes = this._searchMasked(oldNode);
      newNode.masked = true;
      this._rtree.insert(newNode);
      const cell = this._cells.get(createKey(top, left));
      invariant(cell);
      cell._spill = newNode;
      cell.v = ERROR_SPILL;

      // @ts-expect-error correct also for cell nodes, which lack `.masked`
      if (oldNode.masked) {
        // The cell was masked, and remains masked, so nothing changes
        changedRanges = [];
      }
      else {
        // The cell we're writing to was not masked previously. So it may have
        // been blocking some other cells, which might not be blocked by any
        // other cell. So we must find those cells and unmask them.
        changedRanges = [ boundingBoxToRangeCapped(oldNode) ].concat(
          this._unmaskCurrentlyNotBlockedNodes(previouslyBlockedNodes),
        );
      }
    }
    else {
      // The new spilled value of the cell is not blocked by any other cells.
      // So we can safely leave it non-masked. That said, the new dimensions
      // may leave some cells unblocked that were previously blocked. We must
      // find those cells and mark them unmasked.
      //
      // Optimization: If the new bounding box is a strict superset of the old
      // one, we know that there can be no cells masked by the old bounding box
      // but not the new one. So we don't have to check the neighbouring cells.
      if (contains(newNode, oldNode)) {
        changedRanges = [ boundingBoxToRangeCapped(newNode) ];
      }
      else {
        const blockedByOldButNotByNew = this._searchMasked(oldNode).filter(n => !intersects(n, newNode));
        // If any of these cells are _also_ not blocked by any other nodes, we
        // must mark them unmasked.
        changedRanges = combineRanges(oldNode, newNode).concat(
          this._unmaskCurrentlyNotBlockedNodes(blockedByOldButNotByNew),
        );
      }
      if ('matrix' in newNode) {
        newNode.masked = false;
        const cell = this._cells.get(createKey(top, left));
        invariant(cell);
        cell._spill = newNode;
        cell.v = coerceLambdaToError(newNode.matrix.getBoxed(0, 0));
      }
      this._rtree.insert(newNode);
    }
    updateCellNodeSpillAssignments(
      cellNodesInPrevOrNewSpillRange,
      'matrix' in oldNode ? oldNode : null,
      'matrix' in newNode ? newNode : null,
    );

    return changedRanges;
  }

  /**
   * Find any cell nodes in the RTree covered by the given nodes if they are
   * matrix nodes. The second node is optional; if given, it is assumed to have
   * the same anchor node as the first node. Each node is ignored if it is not
   * a matrix node.
   */
  _findCellRTreeNodes (oldNode: RTreeNode, newNode: RTreeNode | null): RTreeCellNode[] {
    const oldIsMatrix = 'matrix' in oldNode && !oldNode.masked;
    const newIsMatrix = newNode && 'matrix' in newNode && !newNode.masked;
    if (!oldIsMatrix && !newIsMatrix) {
      return [];
    }
    const union = newNode
      ? {
        ...oldNode,
        maxX: Math.max(oldNode.maxX, newNode.maxX),
        maxY: Math.max(oldNode.maxY, newNode.maxY),
      }
      : oldNode;
    return this._rtree.search(union).filter(node => 'cell' in node) as RTreeCellNode[];
  }

  /**
   * Visit all formula cells within the given range. Also includes formula cells that spill into the range.
   * @param range zero-based bounds of range, all included.
   */
  visitFormulaCellsIntersecting (range: SlimRange, callback: CallbackWithStop<Cell>): void {
    this._rtree.visitItemsIntersecting(new Range(range).toBoundingBox(), (node, stopSignal) => {
      // @ts-expect-error get node.cell and fall back to anchor cell if matrix node
      const cell = node.cell || this._cells.get(createKey(node.minY, node.minX));
      if (cell?.f) {
        callback(cell, stopSignal);
      }
    });
  }

  /**
   * Iterate all cells in range, except spilled cells
   */
  * iterAnchorCellsInRange (range: SlimRange): IterableIterator<Cell> {
    for (const node of this._rtree.search(new Range(range).toBoundingBox())) {
      if ('cell' in node) {
        yield node.cell;
      }
      else if (node.minX >= range.left && node.minY >= range.top) {
        const cell = this._cells.get(createKey(node.minY, node.minX));
        invariant(cell);
        yield cell;
      }
    }
  }

  /**
   * @returns the cell object, and any
   *   other ranges which may have been affected by masking/unmasking
   */
  writeCellDataByRange (
    { top, left }: { top: number, left: number },
    cellData: CellCSF,
  ): { cell: Cell, changedRanges: Range[] } {
    const isBlockingEdit = 'v' in cellData || 'f' in cellData;

    let cell = this.getCellByCoords(top, left, true);
    if (cell) {
      cell.edit(cellData);
      if (isBlockingEdit && cell._spill && !cell.isSpillAnchor()) {
        // Remove '_spill' from the cell. Removing the '_spill' property
        // prevents the edit we are performing from being cleared
        // alongside the spill when the spill becomes masked.
        cell._spill = null;
      }
    }
    else {
      cell = this._insertCell(cellData, { top, left });
    }

    const range = { top, left };
    let changedRanges: Range[];

    if (isBlockingEdit) {
      changedRanges = this._updateSpillsAffectedByBlockingEdit(cell, range);
    }
    else {
      changedRanges = [ new Range(range) ];
    }

    return { cell, changedRanges };
  }

  _updateSpillsAffectedByBlockingEdit (cell: Cell, range: { top: number, left: number }) {
    const overlappingMatrixNodes = this._rtree.search(new Range(range).toBoundingBox()).filter(node => {
      if (node.minX === range.left && node.minY === range.top) {
        // We are editing the origin cell. We will overwrite it naturally.
        return false;
      }
      invariant('masked' in node, 'node must be matrix R-Tree node');
      // If 'masked' is true, we don't need to consider this node. It was blocked
      // before we edit, and will remain blocked after the edit.
      return !node.masked;
    }) as RTreeMatrixNode[];
    invariant(overlappingMatrixNodes.length < 2, 'there cannot be multiple spills to a single cell simultaneously');

    if (overlappingMatrixNodes.length > 0) {
      // We edited a cell inside of an active spill range, that is NOT the
      // spill anchor.
      //
      // We can mask the spill by re-applying it. When applying it, it will
      // consider the cell we just edited as a blocker and become masked.

      const nodeToBlock = overlappingMatrixNodes[0];
      const spillRange = boundingBoxToRangeCapped(nodeToBlock);
      return this.updateValueAndSpills(spillRange, nodeToBlock.matrix);
    }

    if (cell.isSpillAnchor()) {
      // We are doing one of three things:
      //
      //    * Editing the formula
      //    * Editing the value (and implicitly removing the formula, if present)
      //    * Removing the formula (and implicitly removing the value as well)
      //
      // In any case, the spill is affected and the entire spill should be
      // considered to be changed.
      //
      // PS:  If we're editing the formula and the spill grows in size during
      //      recalculation, the recalculation algorithm is responsible for marking
      //      the new spill area as changed. We cannot do that here.
      const spill = cell._spill!;
      const spillRange = boundingBoxToRangeCapped(spill);

      if (cell.f) {
        return [ spillRange ];
      }
      // This cell is the spill anchor, and we're removing the spill.
      invariant(isCellValue(cell.v), 'cell should have a 1x1 value');
      const changedRanges = this.updateValueAndSpills(spillRange, cell.v);
      invariant(spill.valid === false, 'spill should have become invalid');
      cell._spill = null;
      return changedRanges;
    }

    // The edit does not affect any existing spills, originating in either
    // other cells or the cell being edited. But it does affect the cell itself.
    return [ new Range({ top: range.top, left: range.left }) ];
  }

  _insertCell (cellData: CellCSF, { top, left }: { top: number, left: number }) {
    const cell = new Cell(cellData, constructCellID(top, left), this._cellContainer);
    cell._v = null;
    const cellKey = createKey(top, left);
    this._cells.set(cellKey, cell);
    this._rtree.insert({
      minX: left,
      maxX: left,
      minY: top,
      maxY: top,
      cell,
    });
    const attrs = this._constructCellAttrs(cellData);
    if (attrs) {
      this._attrs.set(cellKey, attrs);
      this._attrsRtree.insert({ minX: left, maxX: left, minY: top, maxY: top, attrs });
    }
    return cell;
  }

  /**
   * Returns null if there is no non-value information to persist in cellData.
   */
  _constructCellAttrs (cellData: CellCSF): Partial<CellAttrs> | null {
    const attrs: Partial<CellAttrs> = {};
    if (cellData.s && Object.keys(cellData.s).length > 0) {
      attrs.s = cellData.s;
    }
    if (cellData.href) {
      attrs.href = cellData.href;
    }
    if (Object.keys(attrs).length > 0) {
      return attrs;
    }
    return null;
  }

  /**
   * Yield all formula cells.
   */
  * iterFormulaCells (): IterableIterator<Cell> {
    for (const cell of this._cells.values()) {
      if (cell.f) {
        yield clearValueIfSpillStale(cell);
      }
    }
  }

  /**
   * Returns an iterator of all cells contained in this class, even individual
   * cells within spilled ranges.
   * @param [includeStyleOnly=false] include cells that have never had a value but only style info
   */
  getCells (includeStyleOnly = false): IterableIterator<Cell> {
    const results = new Map<string, Cell>();
    this._rtree.visitAll(node => {
      if ('cell' in node) {
        if (results.has(node.cell.id)) {
          // Happens when a blank cell and a spilled range overlap.
          return;
        }
        const { cell } = node;
        clearValueIfSpillStale(cell);
        results.set(cell.id, cell);
        return;
      }

      // The `node` is a MatrixNode resulting from the cell spilling.
      //
      // Collect all cells affected by the spill.
      for (const cell of this._iterCellsInSpill(node)) {
        results.set(cell.id, cell);
      }
    });
    if (includeStyleOnly) {
      this._attrsRtree.visitAll(node => {
        const id = constructCellID(node.minY, node.minX);
        if (!results.has(id)) {
          const cell = this.getCellByCoords(node.minY, node.minX);
          if (cell) {
            results.set(id, cell);
          }
        }
      });
    }
    return results.values();
  }

  * _iterCellsInSpill (node: RTreeMatrixNode): IterableIterator<Cell> {
    if (node.masked) {
      // Do not write spill values to cells when a #SPILL! error is present.
      //
      // Additionally, only yield origin cell (not every cell in spill range)
      // since a #SPILL! error should not affect any other cells.
      const originCell = this._cells.get(createKey(node.minY, node.minX));
      invariant(originCell != null, 'spills should always have an origin cell');
      yield originCell;
      return;
    }

    const maxY = node.minY + (node.matrix.populatedHeight - 1);
    const maxX = node.minX + (node.matrix.populatedWidth - 1);

    for (let row = node.minY; row <= maxY; row += 1) {
      for (let col = node.minX; col <= maxX; col += 1) {
        let cell = this._cells.get(createKey(row, col));
        if (!cell) {
          // Spilled into a cell which wasn't present in the CSF.
          cell = new Cell({ v: null }, constructCellID(row, col), this._cellContainer);
        }
        this._assignSpillToCell(cell, node, row, col);
        yield cell;
      }
    }
  }

  /**
   * Use data from `matrixNode` to update the properties of `cell`, including its value.
   * @param cell The cell to update
   * @param matrixNode The matrix node to use to update
   * @param row The row where `cell` is located
   * @param col The col where `cell` is located.
   */
  _assignSpillToCell (cell: Cell, matrixNode: RTreeMatrixNode, row: number, col: number): void {
    const xOffset = col - matrixNode.minX;
    const yOffset = row - matrixNode.minY;
    const value = matrixNode.matrix.getBoxed(xOffset, yOffset);
    const isOriginCell = matrixNode.minX === col && matrixNode.minY === row;
    if (!isOriginCell) {
      invariant(cell.f == null, 'a spill should not be assigned to a cell with a formula ' + cell.id);
    }
    // Non-obvious ordering requirement: we assign _spill before v because
    // CellMutationMonitor has to ignore .v setter mutations if isSpilled()
    cell._spill = matrixNode;
    cell.v = coerceLambdaToError(value);
  }

  getSize (): [width: number, height: number] {
    const [ valuesMaxX, valuesMaxY ] = this._rtree.bounds;
    const [ attrsMaxX, attrsMaxY ] = this._attrsRtree.bounds;

    const maxX = Math.max(valuesMaxX, attrsMaxX);
    const maxY = Math.max(valuesMaxY, attrsMaxY);

    // `maxX` and `maxY` are set to `-Infinity` when there are no nodes in the
    // R-Tree. We want zeroes instead.
    return [ Math.min(Math.max(maxX + 1, 0), MAX_COL), Math.min(Math.max(maxY + 1, 0), MAX_ROW) ];
  }

  /**
   * Search the R-Tree for any nodes in the direction relative to (row, col).
   * @param row Row index
   * @param col Column index
   * @param direction Direction string. One of up, down, left, or right.
   */
  _searchInDirection (row: number, col: number, direction: SearchDirection, offset = 1): RTreeNode[] {
    if (direction === 'right') {
      return this._rtree
        .search({
          minX: col + offset,
          maxX: Infinity,
          minY: row,
          maxY: row,
        })
        .sort((a, b) => a.minX - b.minX);
    }
    else if (direction === 'left') {
      return this._rtree
        .search({
          minX: 0,
          maxX: col - offset,
          minY: row,
          maxY: row,
        })
        .sort((a, b) => a.minX - b.minX);
    }
    else if (direction === 'down') {
      return this._rtree
        .search({
          minX: col,
          maxX: col,
          minY: row + offset,
          maxY: Infinity,
        })
        .sort((a, b) => a.minY - b.minY);
    }
    else if (direction === 'up') {
      return this._rtree
        .search({
          minX: col,
          maxX: col,
          minY: 0,
          maxY: row - offset,
        })
        .sort((a, b) => a.minY - b.minY);
    }
    else {
      throw new Error(`Direction "${direction}" not in ["up", "down", "left", "right"]`);
    }
  }

  _nextValueCellFromMatrix (node: BBox, row: number, col: number, direction: SearchDirection) {
    if (direction === 'right') {
      return this.getCellByCoords(row, Math.max(col + 1, node.minX));
    }
    else if (direction === 'down') {
      return this.getCellByCoords(Math.max(row + 1, node.minY), col);
    }
    else if (direction === 'left') {
      return this.getCellByCoords(row, Math.min(col - 1, node.maxX));
    }
    else if (direction === 'up') {
      return this.getCellByCoords(Math.min(row - 1, node.maxY), col);
    }
    else {
      throw new Error('Invalid search direction ' + direction);
    }
  }

  nextValueCellByID (cellAddr: string, direction: SearchDirection) {
    const [ row, col ] = a1ToRowColumn(cellAddr);
    return this.nextValueCellByCoords(row, col, direction);
  }

  nextValueCellByCoords (row: number, col: number, direction: SearchDirection) {
    const nodes = this._searchInDirection(row, col, direction);
    return this._nextValueCell(row, col, direction, nodes);
  }

  _nextValueCell (row: number, col: number, direction: SearchDirection, nodes: RTreeNode[]) {
    let start, step;
    const reverse = direction === 'up' || direction === 'left';
    if (reverse) {
      start = nodes.length - 1;
      step = -1;
    }
    else {
      start = 0;
      step = 1;
    }
    for (let i = start; i >= 0 && i < nodes.length; i += step) {
      const node = nodes[i];
      if ('cell' in node && node.cell.hasValue()) {
        return clearValueIfSpillStale(node.cell);
      }
      else if ('matrix' in node && node.matrix != null) {
        return this._nextValueCellFromMatrix(node, row, col, direction);
      }
    }
    return null;
  }

  /**
   * Assumes (row, col) is non-blank. Finds the next instance in the given
   * direction of two adjacent cells where the one nearer to (row, col) has a
   * non-blank value, but the one further away does not.
   * @param row Row index
   * @param col Column index
   * @param direction Direction string. One of up, down, left, right.
   * @param nodes Array of RTree nodes
   */
  _finalValueCell (row: number, col: number, direction: SearchDirection, nodes: RTreeNode[]): null | Cell {
    let start: number;
    let step: number;
    let numLeft = nodes.length;
    const reverse = direction === 'up' || direction === 'left';
    if (reverse) {
      start = nodes.length - 1;
      step = -1;
    }
    else {
      start = 0;
      step = 1;
    }
    const [ rowOff, colOff ] = DIRECTION_OFFSETS[direction];

    let i = start;
    while (numLeft >= 0) {
      const node = nodes[i];
      // If `node == null`, it means the array index above is out of bounds.
      if (node == null || ('cell' in node && node.cell.isBlank()) || !intersects(node, coordsToBBox(row, col))) {
        // Should never be out of bounds because of the assumption that the
        // initial (row, col) argument contains a value cell.
        const prevNode = nodes[i - step];
        invariant(prevNode);
        if ('cell' in prevNode && prevNode.cell?.hasValue()) {
          return clearValueIfSpillStale(prevNode.cell);
        }
        else if ('matrix' in prevNode) {
          return this.getCellByCoords(row - rowOff, col - colOff);
        }
        break;
      }
      if (direction === 'up') {
        /* ABCDEF    ABCDEF
         1              x       x is (row, col)
         2  +--+      +--+      box drawing is the node
         3  | x|  ->  |  |
         4  |  |      |  |
         5  +--+      +--+
        */
        row = node.minY - 1;
      }
      else if (direction === 'down') {
        row = node.maxY + 1;
      }
      else if (direction === 'left') {
        col = node.minX - 1;
      }
      else {
        /* if (direction === 'right') */
        col = node.maxX + 1;
      }
      i += step;
      numLeft -= 1;
    }
    return null;
  }

  nextBoundaryByID (cellAddr: string, direction: SearchDirection) {
    const [ row, col ] = a1ToRowColumn(cellAddr);
    return this.nextBoundaryByCoords(row, col, direction);
  }

  nextBoundaryByCoords (row: number, col: number, direction: SearchDirection) {
    const nodes = this._searchInDirection(row, col, direction, 0);
    if (nodes.length === 0) {
      return null;
    }
    const reverse = direction === 'up' || direction === 'left';
    const firstNode = nodes[reverse ? nodes.length - 1 : 0];
    const startingFromValue =
      intersects(firstNode, coordsToBBox(row, col)) && ('matrix' in firstNode || !firstNode.cell.isBlank());
    if (startingFromValue) {
      const valueCell = this._finalValueCell(row, col, direction, nodes);
      if (!valueCell) {
        return null;
      }
      const [ valRow, valCol ] = a1ToRowColumn(valueCell.id);
      const [ rowOff, colOff ] = DIRECTION_OFFSETS[direction];
      const emptyRow = valRow + rowOff;
      const emptyCol = valCol + colOff;
      if (emptyRow < 0 || emptyCol < 0 || emptyRow > MAX_ROW || emptyCol > MAX_COL) {
        return null;
      }
      return {
        nearer: {
          id: valueCell.id,
          row: valRow,
          col: valCol,
          cell: valueCell,
        },
        further: {
          id: constructCellID(emptyRow, emptyCol),
          row: emptyRow,
          col: emptyCol,
          cell: null,
        },
      };
    }
    else {
      // If the starting cell is empty, this routine becomes the same as nextValueCell
      const valueCell = this._nextValueCell(row, col, direction, nodes);
      if (!valueCell) {
        return null;
      }
      const [ valRow, valCol ] = a1ToRowColumn(valueCell.id);
      const [ rowOff, colOff ] = DIRECTION_OFFSETS[direction];
      const emptyRow = valRow - rowOff;
      const emptyCol = valCol - colOff;
      return {
        nearer: {
          id: constructCellID(emptyRow, emptyCol),
          row: emptyRow,
          col: emptyCol,
          cell: null,
        },
        further: {
          id: valueCell.id,
          row: valRow,
          col: valCol,
          cell: valueCell,
        },
      };
    }
  }

  /**
   * Clear the value _and_ formula of each cell in the given range.
   * Other attributes (styles, number formats) are not affected.
   * @returns The places that got changed. This can include cells outside of
   *   `range`, which may have been changed in ways other than clearing them,
   *   because of spill ranges getting unblocked by the clearing of `range`.
   */
  clear (range: Range): (RangeVertexId | CellVertexId)[] {
    const changedCells = new Map<string, Cell>();
    let unblockedOrRemovedSpills: Range[] = [];

    const bbox = range.toBoundingBox();
    const nodes = this._rtree.search(bbox);
    for (const node of nodes) {
      if ('cell' in node) {
        const cell = node.cell;
        cell.clear();
        this._spillResetState.delete(createKey(node.minY, node.minX));
        changedCells.set(cell.id, cell);
        continue;
      }

      const cell = this._cells.get(createKey(node.minY, node.minX));
      invariant(cell && cell._spill, 'a spill should always have an anchor cell');

      if (!intersects(bbox, { ...node, maxX: node.minX, maxY: node.minY })) {
        // We are not clearing the anchor cell of the spill. Do nothing.
        continue;
      }

      const cellNodesInSpillRange = this._findCellRTreeNodes(cell._spill, null);
      // Clearing a spill anchor will also clear all the cells that it spills into. This has the following implications:
      // 1. The R-tree must be updated to remove the old spill range
      // 2. We must keep track of previously blocked spills that will become unblocked as a result
      // 3. We must append all of the cells from the old spill range to our list of changed cells
      // 4. We need to remove the reset state for the spill
      this._spillResetState.delete(createKey(node.minY, node.minX));
      const previousSpillRange = boundingBoxToRange({
        ...cell._spill,
        maxX: Math.min(cell._spill.maxX, MAX_COL),
        maxY: Math.min(cell._spill.maxY, MAX_ROW),
      });
      unblockedOrRemovedSpills = unblockedOrRemovedSpills.concat(this.updateValueAndSpills(previousSpillRange, null));
      invariant(!cell._spill.valid, 'previous spill should have been removed from R-tree and marked invalid');

      if (node.masked) {
        // Only clear the anchor cell, the rest were not governed by it.
        cell._spill = null;
        cell.v = null;
        cell._v = null;
      }
      else {
        // clear values and reset values and spill node assignment in any cell
        // nodes previously belonging to the spill range
        for (const { cell: spilledCell } of cellNodesInSpillRange) {
          spilledCell.v = null;
          spilledCell._v = null;
          spilledCell._spill = null;
        }
      }

      cell.clear();
      changedCells.set(cell.id, cell);
    }

    // There may be previously blocked spills that could be unblocked now. Find them and try to unblock them.
    const blockedSpillNodes = this._searchMasked(range.toBoundingBox());
    unblockedOrRemovedSpills = unblockedOrRemovedSpills.concat(this._unmaskCurrentlyNotBlockedNodes(blockedSpillNodes));

    return [
      ...unblockedOrRemovedSpills.map(range => vertexIdFromRange(this.workbookKey, this.sheetIndex, range)),
      ...[ ...changedCells.values() ].map(
        cell => new CellVertexId(this.workbookKey, this.sheetIndex, ...a1ToRowColumn(cell.id)),
      ),
    ];
  }

  /**
   * @returns The ranges that are now unblocked, whether or not that's because of this call
   */
  _unmaskCurrentlyNotBlockedNodes (maybeBlockedNodes: RTreeMatrixNode[]): Range[] {
    const currentlyNotBlocked: RTreeMatrixNode[] = [];
    for (const node of maybeBlockedNodes) {
      const [ populatedRight, populatedBottom ] = this.getSpillPopulatedRightBottom(node);

      if (populatedRight > MAX_COL || populatedBottom > MAX_ROW) {
        // Cannot be unblocked because it exceeds the sheet bounds
        continue;
      }

      if (this._searchBlockers(node).length === 0) {
        node.masked = false;
        const anchorCell = this._cells.get(createKey(node.minY, node.minX));
        invariant(anchorCell);
        // Usually `anchorCell._spill` is already `node`, but possibly not if
        // `node` is a new node, such as when this is called from moveNodesOnto.
        anchorCell._spill = node;
        anchorCell.v = coerceLambdaToError(node.matrix.getBoxed(0, 0));
        currentlyNotBlocked.push(node);
      }
    }
    return currentlyNotBlocked.map(boundingBoxToRangeCapped);
  }

  /**
   * @param newNodes The nodes to be inserted. Their anchor must intersect with `ontoRange`
   * @param ontoRange The range within which any existing nodes shall be deleted if they conflict with one
   * @returns The ranges that may have changed
   */
  moveNodesOnto (newNodes: RTreeNode[], ontoRange: Range): Range[] {
    const nodesToDelete: RTreeNode[] = [];
    const nodesToMaybeMask: RTreeMatrixNode[] = [];

    const newMatrixNodes = newNodes.filter(isMatrixNode);
    const newMatrixNodesExtendingOutsideOntoRange = newMatrixNodes.filter(
      n => n.maxX > ontoRange.right || n.maxY > ontoRange.bottom,
    );
    const extendedRight = newMatrixNodes.reduce((maxRight, node) => Math.max(maxRight, node.maxX), ontoRange.right);
    const extendedBottom = newMatrixNodes.reduce((maxBottom, node) => Math.max(maxBottom, node.maxY), ontoRange.bottom);
    const ontoRangeExtendedForMatrixNodes = new Range({
      top: ontoRange.top,
      left: ontoRange.left,
      right: Math.min(MAX_COL, extendedRight),
      bottom: Math.min(MAX_ROW, extendedBottom),
    });
    for (const node of this._rtree.search(ontoRangeExtendedForMatrixNodes.toBoundingBox())) {
      const nodeTopLeft = { left: node.minX, top: node.minY };
      if (ontoRange.contains(nodeTopLeft)) {
        // Anchor was inside of `ontoRange`, so we remove the node entirely.
        nodesToDelete.push(node);
        continue;
      }
      for (const newNode of newMatrixNodesExtendingOutsideOntoRange) {
        if (boundingBoxToRangeCapped(newNode).intersects(boundingBoxToRangeCapped(node))) {
          nodesToMaybeMask.push(newNode);
        }
      }
      // Nodes that only intersect the extended range but not `ontoRange` were
      // only of interest because they potentially block matrix nodes among
      // `newNodes` that extend outside ontoRange. So can now disregard them.
      if (ontoRange.intersects(boundingBoxToRangeCapped(node))) {
        // We only get here if `node` has anchor outside `ontoRange` but extends
        // into it. Such a node must be a matrix node. We may need to mask it.
        invariant('matrix' in node);
        // We can only determine whether the node will _actually_ become
        // masked once we insert the new nodes.
        nodesToMaybeMask.push(node);
      }
    }

    for (const node of nodesToDelete) {
      this._rtree.remove(node);
      if ('matrix' in node) {
        node.valid = false;
      }
    }

    // Before we can figure out which nodes to mask, we need to insert the
    // new nodes into the R-Tree.
    this._rtree.load(newNodes);

    const nodesToMask = nodesToMaybeMask.filter(node => this._searchBlockers(node).length > 0);
    for (const node of nodesToMask) {
      node.masked = true;
    }

    const deletedAndMaskedNodes = [ ...nodesToDelete, ...nodesToMask ];

    const newNodesCurrentlyMasked = newNodes.filter(node => 'matrix' in node && node.masked) as RTreeMatrixNode[];

    const nodesToMaybeUnmask = [
      ...newNodesCurrentlyMasked,
      ...deletedAndMaskedNodes.flatMap(blocker => this._searchMasked(blocker)),
    ];

    return [
      ...newNodes.map(boundingBoxToRangeCapped),
      ...deletedAndMaskedNodes.map(boundingBoxToRangeCapped),
      ...this._unmaskCurrentlyNotBlockedNodes(nodesToMaybeUnmask),
    ];
  }

  _moveCellsInHashmap (otherCells: Cells, from: Range, to: Range) {
    const fromCells = this._cells;
    const toCells = otherCells._cells;
    const fromAttrs = this._attrs;
    const toAttrs = otherCells._attrs;

    // `from` may overlap `to`, and `from` may also overlap itself. This
    // can result in deleting a cell before moving it, or moving a cell
    // over a cell that has yet to be moved.
    //
    // This can be avoided by:
    //
    //    1. Making a copy of `from`;
    //    2. deleting every cell in `from` and `to`;
    //    3. moving cells in the copied `from` to `to`.
    //
    const fromCellsCopy = new Map(fromCells.entries());
    const fromAttrsCopy = new Map(fromAttrs.entries());

    /**
     * Find the coordinates that we need to consider via the R-Tree
     * to avoid iterating over every coordinate in large ranges.
     */
    const getCoords = <T>(rtree: RTree<T & BBox>, range: Range) =>
      rtree
        .search(range.toBoundingBox())
        // Ensure that the anchor is contained in range
        .filter(node => range.contains({ left: node.minX, top: node.minY }))
        .map(node => ({ x: node.minX, y: node.minY }));

    const toCellsCoords = getCoords(otherCells._rtree, to);
    const fromCellsCoords = getCoords(this._rtree, from);

    const toAttrsCoords = getCoords(otherCells._attrsRtree, to);
    const fromAttrsCoords = getCoords(this._attrsRtree, from);

    const mapsAndCoords = [
      [ toCells, toCellsCoords ],
      [ fromCells, fromCellsCoords ],
      [ toAttrs, toAttrsCoords ],
      [ fromAttrs, fromAttrsCoords ],
    ] as const;
    for (const [ map, coords ] of mapsAndCoords) {
      coords.forEach(({ x, y }) => map.delete(createKey(y, x)));
    }

    const xDelta = to.left - from.left;
    const yDelta = to.top - from.top;

    for (const { x, y } of fromCellsCoords) {
      const fromKey = createKey(y, x);
      const toKey = createKey(y + yDelta, x + xDelta);

      const cell = fromCellsCopy.get(fromKey);
      invariant(cell != null, 'a cell should exist for every R-Tree node');

      // If the cell was affected by a spill, it might not be affected
      // by a spill once moved. Clear it to be sure.
      //
      // If the cell is still affected by a spill once moved, the spill
      // will be applied when the cell is fetched again.
      if (cell.isSpilled()) {
        (cell as Cell)._spill = null;
        cell.v = null;
      }

      // Update cell to reflect its new location
      cell.id = constructCellID(y + yDelta, x + xDelta);
      cell._container = otherCells._cellContainer;

      toCells.set(toKey, cell);
    }

    for (const { x, y } of fromAttrsCoords) {
      const fromKey = createKey(y, x);
      const toKey = createKey(y + yDelta, x + xDelta);

      const attrs = fromAttrsCopy.get(fromKey);
      invariant(attrs != null, 'attributes should exist for every attribute R-Tree node');
      toAttrs.set(toKey, attrs);
    }
  }

  _moveCellsInRTree (
    otherCells: Cells,
    from: Range,
    to: Range,
  ): { changedRangesInFrom: Range[], changedRangesInTo: Range[] } {
    const nodesToMove = this._rtree.search(from.toBoundingBox()).filter(n => from.top <= n.minY && from.left <= n.minX);
    const attrNodesToMove = this._attrsRtree.search(from.toBoundingBox());
    const attrNodesToRemove = this._attrsRtree.search(to.toBoundingBox());
    for (const node of nodesToMove) {
      this._rtree.remove(node);
      if ('matrix' in node) {
        // Clear reset state for spill, it will be repopulated after the move.
        this._spillResetState.delete(createKey(node.minY, node.minX));
      }
    }
    for (const node of [ ...attrNodesToMove, ...attrNodesToRemove ]) {
      this._attrsRtree.remove(node);
    }

    const maybeCanBeUnmasked = nodesToMove.flatMap(blocker => this._searchMasked(blocker));
    const changedRangesInFrom = [
      ...nodesToMove.map(boundingBoxToRangeCapped),
      ...this._unmaskCurrentlyNotBlockedNodes(maybeCanBeUnmasked),
    ];

    [ ...nodesToMove, ...attrNodesToMove ].forEach(node => {
      const translated = translateRelative(boundingBoxToRangeCapped(node), from, to);
      node.minX = translated.left;
      node.minY = translated.top;
      node.maxX = translated.right;
      node.maxY = translated.bottom;
    });

    for (const node of attrNodesToMove) {
      this._attrsRtree.insert(node);
    }

    const changedRangesInTo = otherCells.moveNodesOnto(nodesToMove, to);
    return { changedRangesInFrom, changedRangesInTo };
  }

  /**
   * @param otherCells Other `Cells` object to exchange cells with.
   * @param from Range within `this` to take cells from.
   * @param to Range within `otherCells` to put cells in.
   * @returns Ranges which may have changed.
   */
  moveCells (otherCells: Cells, from: Range, to: Range): { changedRangesInFrom: Range[], changedRangesInTo: Range[] } {
    this._moveCellsInHashmap(otherCells, from, to);
    return this._moveCellsInRTree(otherCells, from, to);
  }

  setSheetIndex (sheetIndex: number): void {
    this.sheetIndex = sheetIndex;
    this._cellContainer.sheetIndex = sheetIndex;
  }

  getSpillPopulatedRightBottom (node: RTreeMatrixNode) {
    const value = node.matrix;
    if (this.mode === MODE_GOOGLE) {
      const defaultBottomPopulated = value._defaultValue || value._defaultRow.length;
      const defaultRightPopulated = value._defaultValue || value._defaultColumn.length;
      return [
        node.minX + (defaultRightPopulated ? value.width : value.populatedWidth) - 1,
        node.minY + (defaultBottomPopulated ? value.height : value.populatedHeight) - 1,
      ];
    }
    if (this.mode === MODE_EXCEL) {
      return [ node.maxX, node.maxY ];
    }
    return [ node.minX + value.populatedWidth - 1, node.minY + value.populatedHeight - 1 ];
  }

  _makeCell (row: number, column: number, options: { insert: boolean }): Cell {
    if (options.insert) {
      return this._insertCell({}, { top: row, left: column });
    }
    return new Cell({}, constructCellID(row, column), this._cellContainer);
  }
}

export function coerceLambdaToError (boxedValue: MaybeBoxed<ArrayValue>) {
  return isLambda(boxedValue) ? ERROR_CALC_LAMBDA_NOT_ALLOWED : (boxedValue as MaybeBoxed<CellValue>);
}

/**
 * Returns true if we can skip creating a Cell object for this CellCSF.
 *
 * In the past, given that A1:A3={1;2;3} and B1=A1:A100, we would create 100
 * cell objects for B1:B100. The CSF for such sheets will contain a lot of
 * redundant CellCSF objects, which we now ignore.
 */
function isIgnorableCellCSF (cellJson: CellCSF): boolean {
  if (cellJson.s) {
    // Cells will often contain empty style objects.
    //
    // eslint-disable-next-line
    for (const _ in cellJson.s) {
      return false;
    }
  }
  if ('z' in cellJson || 'href' in cellJson) {
    // Retain non-value data (number format, href)
    return false;
  }
  if ('v' in cellJson || 'f' in cellJson) {
    return false;
  }
  return true;
}

function hasValueInformation (cellJson: CellCSF): boolean {
  return 'v' in cellJson || 'z' in cellJson || 'f' in cellJson;
}

/**
 * Find all nodes from the R-tree which overlap the given area, categorize
 * them into cell nodes, unmasked matrix nodes and masked matrix nodes, and find
 * the furthest X and Y coordinates at which any data is found.
 * Helper for `Cells._resolveAreaFromRTree`.
 */
function collectAreaNodes (
  rtree: RTree<RTreeNode>,
  minX: number,
  maxX: number,
  minY: number,
  maxY: number,
  options: ResolveAreaOptions,
): {
    cellNodes: RTreeCellNode[],
    unmaskedMatrixNodes: RTreeMatrixNode[],
    maskedMatrixNodes: RTreeMatrixNode[],
    maxDataX: number,
    maxDataY: number,
  } {
  const nodes = rtree.search({ minX, maxX, minY, maxY });
  const cellNodes: RTreeCellNode[] = [];
  const unmaskedMatrixNodes: RTreeMatrixNode[] = [];
  const maskedMatrixNodes: RTreeMatrixNode[] = [];
  let maxDataY = minY - 1;
  let maxDataX = minX - 1;
  for (const node of nodes) {
    if ('cell' in node) {
      const owningSpillNode = node.cell._spill;
      if (owningSpillNode) {
        // If the spill node is valid, then the value will be populated from
        // that spill node. If invalid, then the real cell value is null (else
        // the cell's value would have been assigned and the old _spill deleted)
        // ... either way, don't populate the resolved area from this cell.
        continue;
      }
      cellNodes.push(node);
      const expandBoundsToIncludeCell =
        options.cropTo === 'cells-with-non-blank-values' ? !isCellValueBlank(node.cell.v) : true;
      if (expandBoundsToIncludeCell) {
        if (node.minY > maxDataY) {
          maxDataY = node.minY;
        }
        if (node.minX > maxDataX) {
          maxDataX = node.minX;
        }
      }
    }
    else if (!node.masked) {
      unmaskedMatrixNodes.push(node);
      const { mxMaxDataX, mxMaxDataY } = determineMatrixDataBounds(node, maxX, maxY, options.returnCells);

      const cappedMaxDataX = Math.min(maxX, mxMaxDataX);
      const cappedMaxDataY = Math.min(maxY, mxMaxDataY);

      if (cappedMaxDataX > maxDataX) {
        maxDataX = cappedMaxDataX;
      }
      if (cappedMaxDataY > maxDataY) {
        maxDataY = cappedMaxDataY;
      }
    }
    else if (node.minX >= minX && node.minY >= minY) {
      // masked matrix node with anchor inside the requested range, so #SPILL! error
      maskedMatrixNodes.push(node);
      if (node.minX > maxDataX) {
        maxDataX = node.minX;
      }
      if (node.minY > maxDataY) {
        maxDataY = node.minY;
      }
    }
  }
  return { cellNodes, unmaskedMatrixNodes, maskedMatrixNodes, maxDataX, maxDataY };
}

/**
 * @param considerDefaultedDataPopulated when set to true, the data in the default quadrants
 *  of the matrix will be considered part of the "populated data bounds".
 */
export function determineMatrixDataBounds (
  node: RTreeMatrixNode,
  maxX: number,
  maxY: number,
  considerDefaultedDataPopulated: boolean,
) {
  const mx = node.matrix;

  const mxHasDefaultXData = !isCellValueBlank(mx._defaultValue) || mx._defaultColumn.some(v => !isCellValueBlank(v));
  const mxHasDefaultYData = !isCellValueBlank(mx._defaultValue) || mx._defaultRow.some(v => !isCellValueBlank(v));

  const useDefaultedMaxX = mxHasDefaultXData && (considerDefaultedDataPopulated || node.maxX < maxX);
  const useDefaultedMaxY = mxHasDefaultYData && (considerDefaultedDataPopulated || node.maxY < maxY);

  let mxMaxDataX: number;
  let mxMaxDataY: number;
  if (useDefaultedMaxX) {
    mxMaxDataX = node.maxX;
  }
  else {
    for (mxMaxDataX = node.minX + mx.populatedWidth - 1; mxMaxDataX > node.minX; mxMaxDataX -= 1) {
      if (populatedColumnHasNonBlankValuw(mx, mxMaxDataX - node.minX)) {
        break;
      }
    }
  }
  if (useDefaultedMaxY) {
    mxMaxDataY = node.maxY;
  }
  else {
    for (mxMaxDataY = node.minY + mx.populatedHeight - 1; mxMaxDataY > node.minY; mxMaxDataY -= 1) {
      if (populatedRowHasNonBlankValue(mx, mxMaxDataY - node.minY)) {
        break;
      }
    }
  }
  return { mxMaxDataX, mxMaxDataY };
}

function populatedColumnHasNonBlankValuw (mx: Matrix, x: number) {
  const popHeight = mx.populatedHeight;
  for (let y = 0; y < popHeight; y++) {
    if (!isCellValueBlank(mx.get(x, y))) {
      return true;
    }
  }
  return false;
}

function populatedRowHasNonBlankValue (mx: Matrix, y: number) {
  const row = mx._data[y];
  return row && row.some(maybeBoxedValue => !isCellValueBlank(unbox(maybeBoxedValue)));
}

/**
 * Update each cell node's spill assignment, and possibly clear its value,
 * such that:
 * * if `newNode` is given and not masked and the cell is inside it, then the
 *   cell is associated with `newNode`
 * * else if `oldNode` is given and not masked and the cell is associated with
 *   it, then the cell's value _and_ `_spill` are cleared.
 * @param oldNode previous spill node if not masked
 * @param newNode new spill node if not masked
 */
function updateCellNodeSpillAssignments (
  nodes: RTreeCellNode[],
  oldNode: RTreeMatrixNode | null,
  newNode: RTreeMatrixNode | null,
) {
  for (const node of nodes) {
    if (newNode && !newNode.masked && intersects(node, newNode)) {
      node.cell._spill = newNode;
    }
    // This cell was in the old spill and is not in the new spill (if any).
    // It may still be in _another_ spill range that got unmasked. But if not,
    // then its value and possible spill association are stale, so clear them.
    else if (oldNode && !oldNode.masked && node.cell._spill === oldNode) {
      node.cell.v = null;
      node.cell._spill = null;
    }
  }
}

/**
 * Return one or two ranges covering both a and b, given that a and b have the
 * same anchor coordinates. Caps right/bottom coordinate at max coordinate
 * bounds even if input bboxes exceed those bounds.
 */
export function combineRanges (a: BBox, b: BBox) {
  invariant(a.minX === b.minX && a.minY === b.minY);
  const rangeA = boundingBoxToRangeCapped(a);
  const rangeB = boundingBoxToRangeCapped(b);
  if (rangeA.contains(rangeB)) {
    return [ rangeA ];
  }
  if (rangeB.contains(rangeA)) {
    return [ rangeB ];
  }
  return [ rangeA, ...rangeB.except(rangeA) ];
}

function isMatrixNode (n: RTreeNode): n is RTreeMatrixNode {
  return 'matrix' in n;
}

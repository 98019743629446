/* eslint-disable no-console */
import { CellVertexId, NameVertexId } from './DependencyGraph';
import type Workbook from './Workbook';
import { vertexIdToCell } from './dep-graph-helpers';
import type Cell from './excel/Cell';
import { evaluateStaticReferenceASTNodeUnbound } from './excel/evaluate';
import { invariant } from './validation';

export type RootsAndLeaves = {
  /** List of cell vertex IDs that have incoming references, but no outgoing references. */
  roots: Array<CellVertexId>,
  /** List of cells that have outgoing references and no (nontrivial) incoming references. */
  leaves: Array<Cell>,
};

export function getRootsAndLeaves (workbook: Workbook): RootsAndLeaves {
  const roots: RootsAndLeaves['roots'] = [];
  const leaves: RootsAndLeaves['leaves'] = [];

  const depGraph = workbook._model._graph;
  const vertices = depGraph._getVertices(workbook.keyInDepGraph) || [];

  const result = { roots, leaves };

  for (const vertex of vertices) {
    if (!(vertex.id instanceof CellVertexId)) {
      continue;
    }
    if (vertex.outDegree > 0) {
      invariant(vertex.data, 'vertex has outgoing dependencies so must have a Cell as its data');
      if (!isNontriviallyReferenced(workbook, vertex.id)) {
        leaves.push(vertex.data);
      }
    }
    else if (
      vertex.inDegree > 0 &&
      vertex.id instanceof CellVertexId &&
      isNontriviallyReferenced(workbook, vertex.id)
    ) {
      roots.push(vertex.id);
    }
  }
  return result;
}

/**
 * Returns true if the given cell is referenced by some formula that isn't just a static reference to the cell.
 */
export function isNontriviallyReferenced (
  wb: Workbook,
  vertexId: CellVertexId | NameVertexId,
  maxRecursionDepth = 100,
): boolean {
  let foundNontrivialReference = false;
  wb._model._graph.visitIncomingVertices(vertexId, ({ id: depVertexId }, stop) => {
    if (maxRecursionDepth < 0) {
      // Don't recurse further; call it nontrivially referenced based on this
      // long chain of purely static references, rather than spend more time and
      // possibly blow the stack
      foundNontrivialReference = true;
      stop.set();
      return;
    }
    const depCell = vertexIdToCell(wb._model, depVertexId);
    if (
      depCell?.f &&
      (!evaluateStaticReferenceASTNodeUnbound.call(wb, depCell._ast) ||
        isNontriviallyReferenced(wb, depVertexId, maxRecursionDepth - 1))
    ) {
      foundNontrivialReference = true;
      stop.set();
    }
  });
  return foundNontrivialReference;
}
